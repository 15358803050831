#loader
    position: fixed
    left: 0
    top: 0
    height: 100vh
    width: 100vw
    background: #fff
    text-align: left
    padding: 2rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    display: none
    &.loading
        display: flex
    &.loaded
        display: none
    .found
        display: none
        text-align: center
    @media (min-width: $mobile)

        background: rgba(black, 0.9)
        z-index: 999999
        .loader__content
            width: 500px
            background: #fff
            padding: 3rem
            border-radius: 10px
            //text-align: left
            display: flex
            justify-content: center
            align-items: flex-start
            flex-direction: column
            .kunden_overlay
                margin: 2rem auto 0
                display: block
            .loader_logo
                width: 220px
                margin: 0 auto 2rem
            #close_button
                font-weight: 400
            .loader
                text-align: center
            .list
                width: 100%



            h2
                font-size: 2rem
                max-width: 400px
            .found

                display: none
                #close_button
                    text-decoration: underline
                    margin: 2rem 0
                    display: block
                    cursor: pointer
                &.active
                    display: block
                    .main__button
                        margin: 2rem 0
                    

                    h3
                        color: $success
                    svg
                        width: 50px
                        height: 50px
                        @supports not (-ms-high-contrast: none)
                            #kreis
                                animation: draw 1s forwards ease
                                stroke-dasharray: 200
                                stroke-dashoffset: 200
                            #check1, #check2
                                animation: draw 1s forwards ease
                                stroke-dasharray: 200
                                stroke-dashoffset: 200
                            #check1
                                animation-delay: 500ms
                            #check2
                                animation-delay: 600ms


#testplatz
    text-align: center
    margin-bottom: 2rem
    h3
        font-family: $value
    .checkbox
        svg 
            width: 5rem


    
.spinner__loader           
    width: 50px
    height: 50px
    border: 5px solid rgba($rot, 0.2)
    border-radius: 50%
    margin: 2rem auto
    position: relative
    animation: rotate 1s linear infinite forwards
    &:before
        content: ""
        position: absolute
        width: 40px
        height: 40px
        left: -5px
        top: -5px
        border-radius: 50%
        border: 5px solid transparent
        border-top-color: $rot



@keyframes rotate
    0%
        transform: rotate(0)
    100%
        transform: rotate(360deg)
@keyframes draw
    0%
        stroke-dashoffset: 200
    100%
        stroke-dashoffset: 0
