.cookie--badge
    position: fixed
    right: 1rem
    bottom: 1rem
    border-radius: 50%
    width: 5rem
    height: 5rem
    display: flex
    align-items: center
    justify-content: center
    background: $rot
    cursor: pointer
    box-shadow: 0 0 10px rgba(black, 0.15)
    @media (min-width: 1200px)
        z-index: 20
    
// Cookiebot
@media (max-width: 600px)
    .CybotCookiebotDialogBodyLevelButtonWrapper
        display: block!important
#CybotCookiebotDialogDetailFooter a
    font-size: inherit!important
#CybotCookiebotDialogDetailBodyContentTabs a
    font-size: 1.2rem!important
#CybotCookiebotDialog
    position: fixed
    top: 50% !important
    left: 50% !important
    transform: translate(-50%,-50%) !important
    width: 90% !important
    max-width: 61rem !important
    box-shadow: 0 0 100vw 100vw rgba(0,0,0,0.5)!important
    padding: 2rem!important
    box-sizing: border-box!important
    #CybotCookiebotDialogBodyLevelDetailsButton
        font-weight: normal!important
        text-decoration: none!important
    #CybotCookiebotDialogBody
        padding: 0
        width: 100%
        max-width: 100%
    #CybotCookiebotDialogPoweredbyLink
        display: none
    #CybotCookiebotDialogBodyContent
        padding: 0
        padding-bottom: 2rem
    *
        font-family: $font!important
    #CybotCookiebotDialogBodyLevelWrapper
        display: flex!important
        flex-wrap: wrap
        justify-content: flex-start
    #CybotCookiebotDialogBodyLevelButtonsSelectPane
        padding: 0!important
        border: none!important
    #CybotCookiebotDialogBodyLevelDetailsWrapper
        padding: 0!important
        background: white!important
        border: none!important
        text-align: right!important


    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper
        order: 2
        display: flex !important
        justify-content: space-between
        align-items: flex-end
        width: 100%
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll
            display: flex !important
            padding: 1rem 2rem !important
            align-items: center
            justify-content: center
            margin: 1rem 0 0 1rem !important
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection
            background: white !important
            border: none !important
            padding-left: 0 !important
            padding-bottom: 0 !important
            color: $grau!important
            margin-left: 0!important
            justify-content: flex-start
            font-weight: normal!important
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll
            background: $rot !important
            border: 1px solid $rot !important

    #CybotCookiebotDialogBodyLevelButtons
        margin: 0 !important
        width: 100%

    
    
.cookie-banner-close button
    padding: 0
    text-align: right
    font-size: 1.2rem
    background: transparent
    border: none
    border: 2px solid #ffffff
    color: #ffffff
    padding: 10px 20px
    border-radius: 22px
    width: 100%
    display: block
    // margin-top: 1rem
    
    @media screen and (min-width: 800px)
        width: auto
    @media screen and (max-width: 800px)
        padding: 0
        border: none
        text-align: right
        text-decoration: underline
        font-size: 1.2rem
        margin-left: auto
.cookie-buttons
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 1rem


.cookie-banner-content p,
.cookie-content, .cookie-banner-content div a 
    font-size: 1.2rem!important
    //margin-bottom: 5px
    color: #ffffff
    width: 100%
    //float: left
    //max-width: 70%
    display: inline!important
    @media screen and (min-width: $max-width)
        //max-width: 80%
        //margin-right: 95px
        margin-top: 5px
        margin-bottom: 0
        display: block

.cookie-content
    //margin-left: auto
    @media (max-width: 600px)
        max-width: 100%
        //margin-bottom: 10px

.cookie-banner-wrapper 
    max-width: $max-width
    
  
.cookie-banner-overContent .cookie-banner 
    z-index: 88888
  

.cookie-banner-buttons 
    display: flex
  

.cookie-banner-content 
    margin: 0 auto
    display: flex
    justify-content: space-between
    @media (max-width: 600px)
        flex-direction: column
  

.cookie-banner-content div a 
    font-size: 1rem
    font-weight: bold
    display: inline-block
  

.cookie-banner-close 
    display: inline
    font-size: 1.2rem
    text-decoration: underline
    color: #000
    cursor: pointer
  
.cookie-banner-details 
    line-height: 0!important
  

.cookie-banner 
    display: none
    z-index: 9999
    background: #444444
    border-top: 1px solid #ffffff
    position: fixed
    width: 100%
    max-width: 1200px
    bottom: 0
    padding: 1rem
    left: 50%
    transform: translateX(-50%)
  

.cookie-banner-wrapper 
    // padding: 0 1rem
    margin: 0 auto
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    display: -webkit-box
    display: -ms-flexbox
    display: flex
  