form
	max-width: 100%
	overflow-x: hidden
	background: #fff
	z-index: 0
	position: relative
	.form__header
		margin-bottom: 2rem
		text-decoration: none
		.form--heading
			color: $rot
			margin-bottom: 0
			@media (min-width: $mobile)
				font-size: 2rem!important
		h4
			color: $rot
			font-size: 1.6rem
			font-weight: 600
			span
				font: inherit
				color: inherit
	.form__content
		.question
			display: none
			&.active
				display: block
		strong
			margin-bottom: 0.5rem
			display: block
			font-size: 1.8rem
			font-family: $value
			text-align: center
			line-height: 1.2
		*
			font-size: 1.6rem
		.btn-outline
			display: block
			width: 100%
			padding: 1rem 2rem
			color: $rot
			text-align: center
			border: 2px solid $hellgrau
			border-radius: 10rem
			// margin-bottom: 1rem
			transition: all 200ms ease
			cursor: pointer
			font-size: 1.8rem
			@media (min-width: $mobile)
				&:hover
					border-color: $rot
		input[type="radio"]
			margin-bottom: 0
			display: none
			&:checked
				&~.btn-outline
					border-color: $rot
		input[type="text"], input[type="number"],input[type="email"],input[type="password"]
			margin-bottom: 0
			&:focus
				outline: none

			outline: none
			border: 2px solid $hellgrau
			padding: 1rem 2rem
			width: 100%
			// margin-bottom: 1rem
			border-radius: 3rem
			font-size: 1.8rem
			text-align: center
		.main__button
			font-size: 1.8rem
			margin-bottom: 2rem
		.inputGroup
			display: flex
			align-items: center
			position: relative
			flex-wrap: wrap
			margin-bottom: 1rem
			.toggle
				margin-right: 1rem
			#check, #checksession
				display: none
				&:checked
					&~label
						.toggle
							&:after
								left: 2.2rem
								background: $rot
			&.success
				input
					border-color: $success
					color: $success
					display: block
				&:before
					content: ""
					position: absolute
					top: 0
					right: 0
					width: 4rem
					height: calc(100% - 4px)
					border-radius: 0 3rem 3rem 0
					background: url('../img/icn-checkmark-success.svg') center no-repeat
					background-size: 2rem
					background-color: white
					border: 2px solid $success
					border-left: none
		.toggle_check
			display: flex
			align-items: center
			span
				font-size: 1.4rem
				line-height: 1.4
	#timeout--notice
		strong
			display: inline-block
			color: $success
			font-size: inherit!important
			margin: 0
		text-align: center

.alert
	font-weight: 700
	color: $rot
	margin: 1rem 0
	text-align: center
	display: block
	min-width: 100%

#bullets
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	max-width: 380px
	margin: 1rem auto
	padding: 0 2rem 3rem
	.bullet 
		display: block
		border-radius: 50%
		width: 20px
		height: 20px
		background: #fff
		border: 2px solid $grau
		position: relative
		transition: all 200ms ease
		
		&:before
			transition: all 200ms ease
			content: ""
			position: absolute
			left: 50%
			top: 50%
			transform: translate(-50%,-50%) scale(0)
			width: 75%			
			height: 75%			
			border-radius: 50%
			background: $grau
			opacity: 0
		&:after
			transition: all 200ms ease
			right: 100%
			content: ""
			position: absolute
			width: 180%
			height: 2px 
			z-index: -1
			background: $grau
			top: 50%
			transform: translateY(-50%)
		&.activated
			border: 2px solid $grau

			&:before
				opacity: 1
				background: $grau
				transform: translate(-50%,-50%) scale(1)
			&:after
				background: $grau
			&:not(.disabled)
				cursor: pointer
		&.active
			border: 2px solid $rot
			&:before
				opacity: 1
				background: $rot
				transform: translate(-50%,-50%) scale(1)
			&:after
				background: $rot
		&:first-child
			&:after
				display: none
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
	-webkit-appearance: none!important

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus 
	box-shadow: 0 0 0px 1000px #fff inset
	transition: background-color 5000s ease-in-out 0s
.text--success
	margin: 2rem 0
	display: block
	color: $success
	font-weight: bold

#form--modal
	transition: all 500ms ease
	z-index: 10
	position: fixed
	left: 0
	top: 0
	width: 100vw
	height: 100vh
	background: rgba(black, 0.8)
	display: flex
	align-items: center
	justify-content: center
	visibility: hidden
	opacity: 0
		
	.modal--content
		background: white
		padding: 2rem
		width: 90%
		max-width: 60rem
		transition: inherit
		transform: translateY(-20%)
		p
			margin-bottom: 2rem
	&.active
		visibility: visible
		opacity: 1
		.modal--content
			transform: translateY(0)

.logo--inputs
	display: flex
	flex-wrap: wrap
	.inputGroup
		flex: 1 0 45%
		&:nth-child(odd)
			margin-right: 1rem
		&:last-child
			margin-right: 0
		
		.btn-outline
			padding: 1rem!important
			&:before
				content: none!important
			@media (min-width: $mobile-s)
				padding-left: 6rem!important
				&:before
					content: ''!important
			img
				max-width: 100%
				height: auto
#progress
	margin: 4rem 0 1rem
	background: $hellgrau
	width: 100%
	height: 2rem
	border: 1px solid lighten( $grau, 30%) 
	position: relative
	.progress--bar
		position: absolute
		top: 0
		left: 0
		height: 100%
		background: $rot
		transition: width 300ms ease
		width: 0
#success--info
	color: $success
	text-align: center!important


.aktion
	#form
		@media (min-width: $mobile)
			height: 540px
		.form__content
			// display: flex
			// flex-direction: column
			// justify-content: center
.login--form
	padding: 2rem