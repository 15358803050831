footer
    .content
        background: $hellgrau-bg
        padding: 3rem
        width: 100%
        max-width: 120rem
        margin: 0 auto
        order: 6
    //padding: 1.5rem 2rem
    background: #fff
    text-align: center

    
    p
        font-size: 1.2rem
        display: block
        margin: 1rem 0 1rem 2rem
    .legal--links
        order: 3
        a
            margin: 0 2rem 0 0
    a
        color: $rot
        font-size: 1.2rem
        text-decoration: none
        font-weight: 400
        display: inline-block
        //margin: 2rem 2rem 0
    .phone
        font-size: 1.6rem
        font-family: $value
        font-weight: bold
        margin-top: 2rem
        .phone__icon
            width: 1.6rem
            height: 1.6rem
            // height: auto
            margin-right: 0.5rem
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
                width: auto
                height: auto
    @media (min-width: 900px)
        display: flex
        align-items: center
        flex-wrap: wrap
        justify-content: space-between
        .phone
            order: 2
            font-size: 2rem
            margin: 2rem 0
            .phone__icon
                width: 2.5rem!important
                height: 2.5rem!important
        p
            &:first-child
                order: 1
            &:last-child
                order: 3
            font-size: 1.4rem
            *
                font-size: 1.4rem
#home
    footer
        padding-bottom: 100px
        @media (min-width: $mobile)
            padding-bottom: 150px