#hoertester
	#hoergeraete
		background: $hellgrau-bg
		.content
			padding-bottom: 3rem

	footer
		padding-bottom: 100px
		@media(min-width: $mobile)
			padding-bottom: 150px

#kundenzufriedenheit
	.soft--row
		display: flex
		align-items: center
		.ohr
			height: 100px
			width: 100px
			min-height: 100px
			min-width: 100px
			border-radius: 50%
			background: url(../img/ohr.jpg) center
			background-size: auto
			background-size: cover
			display: inline-block
			margin-right: 2rem
		h2
			margin-bottom: 0
		margin-bottom: 2rem