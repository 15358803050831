@mixin mobile
	@media (min-width: $mobile-s)
		@content
@mixin mobile-m
	@media (min-width: $mobile-m)
		@content
@mixin tablet
	@media (min-width: $mobile)
		@content
@mixin tablet-max
	@media (max-width: $mobile - 1)
		@content
@mixin tablet-large
	@media (min-width: $tablet-large)
		@content
@mixin desktop
	@media (min-width: $max-width)
		@content
