#kundenzufriedenheit
    h2
        color: $rot
    h3
        font-size: 1.8rem
        line-height: 1.4
    .col-1
        margin-bottom: 3rem
    @media (min-width: $mobile)
        display: flex
        .col-1
            flex: 1 0 50%
            max-width: 50%
            &:first-child
                padding-right: 3rem