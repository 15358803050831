#modal, #lightbox
    position: fixed
    width: 100vw
    height: 100vh
    left: 0
    right: 0
    background: rgba($black, 0.95)
    padding: 2rem
    z-index: 99
    display: flex
    align-items: center
    justify-content: center
    .modal--content
        padding: 2rem
        border-radius: 0.5rem
        background: white
        box-shadow: 0 0 1rem rgba(black, 0.15)
        max-width: 60rem
        font-size: 1.6rem
        position: relative
        h3, p, strong
            text-align: center
        img
            display: block
            margin: 0 auto
    .close
        position: absolute
        right: 2rem
        top: 2rem
        display: inline-block
        margin-left: auto
        text-align: right
        cursor: pointer
        font-size: 2rem
        margin-bottom: 2rem
        color: $rot