.slick-track
    display: flex
    align-items: stretch
    justify-content: center

    .slick-slide
        // display: flex
        height: auto
        align-items: center //optional
        justify-content: center
        flex-direction: column