@import 'danke'
@import 'testplatz'

.vorsorgewochen
    max-width: 20rem
    position: absolute
    top: 2rem
    right: 2rem
    // max-width: 40vw
    max-height: 50%
    height: auto
    @media (min-width: $mobile)
        left: 2rem
        bottom: 2rem
        right: auto
        top: auto