.inaktiv
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    img
        margin-bottom: 2rem
    .buttons
        margin-top: 2rem
        @media (max-width: 600px)
            .main__button
                margin-bottom: 2rem
                display: block
    h1
        color: black