#warum
    .content
        padding-bottom: 1rem
    @media (min-width: $mobile)
        display: flex
        align-items: center
        img
            flex: 1 0 50%
            max-width: 50%
        .content
            max-width: 50%
            flex: 1 0 50%