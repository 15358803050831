#loginpage
	display: flex
	align-items: center
	justify-content: center
	.main__content
		padding: 2rem
		hr
			margin: 4rem 0
			border: none
			width: 100%
			height: 1px
			background: #efefef
		.message
			display: flex
			align-items: center
			justify-content: center
			padding: 1rem 2rem
			font-size: 1.6rem
			color: white
			margin-bottom: 2rem
			transition: all 300ms ease
			&.success
				background: rgba($success, 0.3)
				border: 1px solid $success
				color: $success
			&.error
				background: rgba($rot, 0.2)
				border: 1px solid $rot
				color: $rot
		.fadeout
			opacity: 0
			height: 0
			padding: 0
			overflow: hidden

