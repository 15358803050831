.main__button
	border: none
	display: flex
	align-items: center
	justify-content: center
	font-size: 1.6rem
	text-decoration: none
	border-radius: 10rem
	background: $rot
	color: white
	font-family: $value
	font-weight: bold
	text-align: center
	padding: 1rem 2rem
	cursor: pointer
	position: relative
	overflow: hidden
	transition: all 200ms ease
	width: 100%
	text-align: center
	.icn-right
		margin-left: 1rem
	&:after
		transition: inherit
		content: ""
		width: 0
		height: 100%
		border-radius: 50%
		background: rgba(white, 0.2)
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
	&:hover
		&:after
			width: 100vw
			height: 100vw
	&.small
		width: auto
		display: inline-block
		margin: 0 1rem
	&.white
		color: $rot
		width: auto
		background: white
		border: 2px solid $rot
		display: inline-block
		margin: 0 1rem
		&:after
			background: rgba($rot, 0.15)
	&#submit_label
		flex-direction: column
		span
			display: block
			color: white
			font-family: $value
			font-weight: bold
		.large
			font-size: 1.8rem
	@media (min-width: $mobile)
		font-size: 1.8rem

.main__button_content
	display: block
	text-decoration: none
	border-radius: 10rem
	background: $rot
	color: white
	font-family: $value
	font-weight: bold
	text-align: center
	padding: 1rem 2rem
	cursor: pointer
	position: relative
	overflow: hidden
	transition: all 200ms ease
	&:after
		transition: inherit
		content: ""
		width: 0
		height: 100%
		border-radius: 50%
		background: rgba(white, 0.2)
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
	&:hover
		&:after
			width: 100vw
			height: 100vw
	@media (min-width: $mobile)
		font-size: 1.8rem
.toggle
	width: 5rem
	min-width: 5rem
	height: 3rem
	border: 2px solid $hellgrau
	border-radius: 50px
	position: relative
	display: block
	cursor: pointer
	&:after
		content: ""
		position: absolute
		height: 2.2rem
		width: 2.2rem
		border-radius: 50%
		background: $hellgrau
		top: 50%
		left: 2px
		transform: translateY(-50%)
		transition: all 150ms ease
	&.active
		&:after
			left: 2.2rem
			background: $rot
.info--holder
	position: relative
	display: flex
	align-items: center
	justify-content: space-between
	text-align: left
.info--button
	display: inline-flex
	width: 2.5rem
	height: 2.5rem
	background: $success
	font-family: Georgia, serif!important
	margin-left: 2rem
	align-items: center
	justify-content: center
	border-radius: 50%
	color: white
	position: relative
	z-index: 1
	cursor: pointer
	&:before
		content: ""
		transition: transform 200ms ease
		background: $success
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%) scale(1)
		width: 100%
		height: 100%
		border-radius: 50%
		opacity: 0.5
		z-index: -1
	&:hover
		&:before
			transform: translate(-50%,-50%) scale(1.3)
