#corona
    background: $rot
    .content
        padding: 1rem
    *
        color: white
    h2
        text-align: center
        margin: 0
        @media (min-width: $mobile)
            // text-align: left
            font-size: 2.5rem
            line-height: 1.2
    .corona--grid
        display: grid
        align-items: center
        grid-template-areas: 'text text text text' 'desinfektion waschen handschuh mundschutz'
        grid-template-columns: 1fr 1fr 1fr 1fr
        grid-template-rows: auto auto
        grid-gap: 1rem
        .grid--item
            text-align: center
            img
                max-width: 8rem
            
            &:nth-child(1)
                grid-area: desinfektion
            &:nth-child(2)
                grid-area: waschen
            &:nth-child(3)
                grid-area: text
            &:nth-child(4)
                grid-area: handschuh
            &:nth-child(5)
                grid-area: mundschutz
        @media (min-width: 640px)
            grid-template-areas: 'desinfektion waschen text handschuh mundschutz'
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr
            grid-template-rows: 1fr
            

.testplatz--page
    // #corona
    //     @media (min-width: $mobile)
    //         h2
    //             text-align: center
    //             margin: 1rem 0
    //             flex: 1 0 20%
    //             text-align: left
    //         .content
    //             padding: 0 2rem
    //             display: flex
    //             align-items: center
    //             .corona--grid
    //                 flex: 1 0 50%
    //                 grid-gap: 0

    #corona--image
        .corona--3-grid
            display: grid
            grid-template-areas: 'bild1 bild2' 'text text'
            grid-template-rows: auto auto
            grid-template-columns: 1fr 1fr
            .grid--item
                background: $rot
                &:nth-child(1)
                    grid-area: bild1
                &:nth-child(2)
                    grid-area: bild2
                &:nth-child(3)
                    grid-area: text
                    padding: 3rem 1rem
                    *
                        color: white
                        text-align: center
            @media (min-width: $mobile)
                grid-template-areas: "bild1 text bild2"
                grid-template-columns: 1fr 1fr 1fr
                grid-template-rows: auto
                .grid--item
                    &:nth-child(3)
                        display: flex
                        align-items: center
                        justify-content: center
                        padding: 3rem