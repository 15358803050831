#nulltarif-hoergeraete
	*
		font-family: "Noto sans", sans-serif
	.main__content
		width: 100%
		max-width: 100%
		display: grid
		grid-template-areas: "image" "content" "benefits" "footer"
		grid-template-columns: 1fr
		background: #E8E8E8
		@include mobile-m
			background: white
			grid-template-areas: "image content" "benefits benefits" "footer footer"
			grid-template-columns: 45% 55%
		@include tablet-large
			grid-template-columns: 40% 60%
			grid-template-areas: "image content" "title benefits" "footer footer"
		grid-template-rows: auto auto
		
		footer
			grid-area: footer
			padding: 4rem 2rem
			.footer--content
				width: 100%
				max-width: 1600px
				margin: 0 auto
				display: grid
				align-items: center
				justify-content: space-between
				grid-template-rows: auto
				grid-gap: 2rem
				grid-template-areas:  "phone" "links" "copyright" "legal"
				grid-template-columns: 1fr
				@include desktop
					grid-template-columns: 1fr auto 1fr
					grid-template-areas: "copyright phone links" "legal legal legal"
				*
					margin: 0
				.phone
					grid-area: phone
					font-size: 2rem
					@include desktop
						font-size: 3.6rem
					img
						margin-right: 1.5rem
						width: 2rem!important
						height: 2rem!important
						@include desktop
							width: 3.6rem!important
							height: 3.6rem!important

				.copyright
					grid-area: copyright
					@include desktop
						text-align: left
				.legal--links
					grid-area: links
					display: flex
					justify-content: center
					flex-direction: column
					@include mobile
						flex-direction: row
						flex-wrap: nowrap
					@include desktop
						text-align: right
						justify-content: flex-end
					a
						margin: 0 0 2rem 0
						@include mobile
							margin: 0 1rem
						color: black
			.legal
				grid-area: legal
				color: #C9C9C9!important
				margin-top: 2rem
			

		.boxed--content
			width: 100%
			max-width: 45rem
			margin: 0 auto

		.header--section
			grid-area: image
			background: linear-gradient(342.22deg, #444444 28.56%, #131313 109.37%)
			padding: 2rem 2rem 0
			@include mobile
				padding: 4rem 4rem 0
			display: flex
			flex-direction: column
			justify-content: space-between
			.header--logo
				width: 12rem
				@include mobile
					width: 16rem
				height: auto
				margin-bottom: 2vw
			.header--image
				width: 70%
				max-width: 30rem
				@include mobile-m
					width: 90%
					max-width: 40rem
				margin: 0 auto
				display: block

		.text--area
			grid-area: image
			padding: 6rem 2rem 0 2rem
			@include mobile-m
				padding: 10rem 2rem 0 4rem
			@include tablet-large
				text-align: left
				grid-area: title
				padding: 2rem 2rem
				background: #444444
				display: flex
				align-items: center
			h2
				color: white
				font-size: 2rem
				@include tablet-large
					font-size: 3.6rem
				margin: 0
		
		.benefits
			grid-area: benefits
			background: #E8E8E8
			border: none!important
			padding: 2rem
			.benefits--list
				@include tablet
					display: flex
					align-items: flex-end
					justify-content: space-between
				li
					list-style-type: none
					text-align: center
					margin-left: 0 
					margin-bottom: 2rem
					@include tablet
						margin-bottom: 0
						padding: 1rem 2rem 
					@include mobile-m
						padding: 1rem 4rem 
					&:not(:last-child)
						@include tablet
							border-right: 1px solid white
					img
						width: 4rem
						margin-bottom: 1rem
						@include tablet
							width: 3rem
							margin-bottom: 2rem
						@include tablet-large
							width: 5rem
					p
						font-size: 1.6rem

		.header--content
			grid-area: content
			padding: 2rem
			display: flex
			align-items: center
			justify-content: center
			background: white
			border-radius: 1rem
			width: calc(100% - 2rem)
			margin: -16rem auto 0
			@media (min-width: 360px)
				margin: -20rem auto 0
				width: calc(100% - 4rem)
			@include mobile-m
				margin: 0
				border-radius: 0
				width: 100%
			form
				width: 100%
				max-width: 70rem
				.main__button, .btn-outline, .btn-outline-input
					border-radius: 1rem
					font-size: 1.6rem
					padding: 1rem 2rem
					@include tablet-large
						padding: 2rem 4rem
						font-size: 2rem
				.inputGroup.success::before
					border-radius: 0 1rem 1rem 0
				em
					font-style: italic
					font-size: 1.4rem
					line-height: 1.2
					display: block
					@include mobile-m
						font-size: 1.6rem
					@include tablet-large
						font-size: 2rem
				#bullets
					margin-top: 4rem
					padding: 0 2rem
					margin-bottom: 0
				.earlybird
					margin: 0 auto
					text-align: center
				.inputSection
					> strong
						font-size: 3rem
						text-align: left
						line-height: 1.4
				.icon--list
					margin: 2rem 0
					@include mobile-m
						margin: 4rem 0
					li
						list-style-type: none
						margin-bottom: 2rem
						margin-left: 0
						// text-align: center
						display: flex
						align-items: center
						@include mobile-m
							text-align: left
							margin-bottom: 2rem
						@include tablet-large
							margin-bottom: 4rem
						img
							margin-right: 2rem
							width: 3rem
							@include tablet-large
								width: 5rem
						p
							font-size: 1.4rem
							@include mobile-m
								font-size: 1.6rem
							@include tablet-large
								font-size: 2rem
				h1
					color: black
					font-size: 1.8rem
					margin-bottom: 1rem
					@include mobile-m
						font-size: 2.4rem
						margin-bottom: 2rem
					@include tablet-large
						font-size: 5rem
					span
						color: $rot
						font: inherit
						text-decoration: underline

	&.danke--page
		h1
			display: flex
			align-items: flex-start
			span
				background: $rot
				padding: 1rem
				border-radius: 50%
				width: 3rem
				min-width: 3rem
				height: 3rem
				@include tablet
					width: 5rem
					min-width: 5rem
					height: 5rem
				display: block
				position: relative
				margin-right: 1rem
				img
					position: absolute
					left: 50%
					top: 50%
					transform: translate(-50%, -50%)
					width: 60%
		.danke--content-box
			.phone
				margin-top: 3rem
				font-size: 2.4rem
				font-weight: bold
				img
					margin-right: 1rem

