#dynamic__footer
    text-decoration: none
    position: fixed
    left: 50%
    transform: translate(-50%,100%)
    bottom: 0
    width: 100%
    height: 100px
    background: $rot
    text-align: center
    cursor: pointer
    // transform: translateY(100%)
    transition: 500ms ease
    padding: 0 2rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    h2, h4
        color: white
        margin-bottom: 0
        font-family: $value
        span
            font: inherit
            color: inherit
    h4
        font-weight: bold
    &.active
        transform: translate(-50%, 0)
    @media (min-width: $mobile)
        height: 150px
        img
            width: 3rem