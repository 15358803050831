#header
	padding: 0
.header
	display: grid
	grid-template-areas: 'image-1 zitat' 'form form'
	grid-template-columns: 1fr 1fr
	grid-template-rows: 55vw auto
	background: $hellgrau-bg    
	height: auto
	padding: 0
	@media (min-width: $mobile)
		grid-template-rows: auto auto
	&.clean
		grid-template-columns: 2fr 3fr
		grid-template-areas: 'image-1 zitat'
		align-items: center
		padding: 2rem 0
	
		.header__zitat
			display: flex
			justify-content: flex-end
			padding: 3rem 0
			
			.zitat--content
				display: inline-block
				padding: 2rem
				background: white!important
	&.berlin, &.amplifon
		grid-template-columns: 2fr 3fr
		margin-bottom: 0!important
		.header__image
			grid-row-end: 4
		.header__zitat
			display: flex
			align-items: center
			justify-content: center
			position: relative
			&.end
				align-items: flex-end
			.zitat--content
				padding-left: 0
				h2
					margin: 0
					font-size: 2rem
		@media (min-width: 640px)
			.header__zitat
				.zitat--content
					h2
						font-size: 3rem
		@media (min-width: $mobile)
			grid-template-columns: 1fr 1fr
			.header__zitat
				display: block
				.zitat--content
					h2
						font-size: 4rem
	&.aktion
		grid-template-columns: 21% 1fr 
		grid-template-rows: 55vw auto
		@media (min-width: $mobile)
			grid-template-columns: 1fr 1fr
			grid-template-rows: auto auto
		.header__image
			height: 55vw
			@media (min-width: $mobile)
				height: 100%

		.header__zitat
			padding-left: 0
			justify-content: flex-end
			.zitat--content
				justify-self: flex-end
				h2
					text-align: right
					max-width: 60vw
					font-size: 1.2rem
					line-height: 1.2
					@media (min-width: 540px)
						font-size: 2rem
					@media (min-width: $mobile)
						padding-right: 2rem
						max-width: 100%
						text-align: center

	&__image
		width: 100%
		height: 50vw
		position: relative
		// max-height: 70rem
		background-size: cover!important
		grid-column: 1 / -1
		grid-column-end: 3
		grid-row: 1
		background: url('../img/header-waves.jpg') left 30% top 10% no-repeat
		&.header__aktion
			background: url('../img/header-aktion.jpg') left 30% top 10% no-repeat
		&.clean
			min-height: 100%
			background-position: top left 30%
			@media (min-width: $mobile)
				min-height: 30rem
				height: 40vw
				max-height: 60rem
		&.berlin, &.amplifon
			background: url('../img/visual_corona.jpg') left 30% top 10% no-repeat
		@media (min-width: 640px)
			background-size: cover!important
			background-position: center!important
		&.header--image-hoertester
			background-image: url('../img/header-hoertester.jpg')
			background-size: 180%!important
			background-position: left center!important
			@media (min-width: $mobile)
				background-size: cover!important


	&__zitat
		grid-area: zitat
		padding: 2rem
		text-align: right

		img
			display: block
			margin-left: auto
			margin-bottom: 1rem
			text-align: right
		small
			font-size: 1rem
			color: $black
			text-align: right
			margin-top: 1rem
		.main__button
			margin-top: 3rem
			width: auto
			display: flex
			align-items: center
			img
				margin-bottom: 0
	&__form
		grid-area: form
		.form__header
			display: block
			padding: 1rem 2rem 3rem
			text-align: center
			background: $rot
			position: relative
			&:before
				content: ""
				position: absolute
				bottom: 0
				right: 50%
				height: 0rem
				width: 0
				border-left: 50vw solid white
				border-bottom: 1.5rem solid white
				border-right: 1.5rem solid transparent
			&:after
				content: ""
				position: absolute
				bottom: 0
				left: 50%
				height: 0rem
				width: 0
				border-right: 50vw solid white
				border-bottom: 1.5rem solid white
				border-left: 1.5rem solid transparent


			*
				color: white!important
				font-family: $value
		.form__content
			padding: 0 2rem 2rem
	@media (min-width: $mobile)
		grid-template-areas: 'image-1 zitat' 'image-1 form' 'benefits form'
		margin-bottom: -6rem!important
		&.clean
			grid-template-areas: 'image-1 zitat'
			grid-template-rows: auto
			align-items: center
			grid-template-columns: 50% 50%

		&__image
			// width: 100%
			height: auto
			// min-height: 170px
			//max-height: 100%
			background-size: cover!important
			background-position: left 40% center!important
			grid-column: 1 / -1
			grid-column-end: 3
			grid-row: 1 / 3
			&.clean
				background-position: top 20% left 30%!important
				grid-row: 1
		&__form
			padding: 0 2rem
			form
				background: #fff
				z-index: 0
				box-shadow: 0 0 1rem rgba(black, 0.2)
				height: 525px
				#bullets
					z-index: 10
					position: relative
				.form__content
					height: 345px
		&__benefits
			grid-area: benefits
			padding: 3rem
			ul
				li
					font-weight: bold
		&__zitat
			text-align: left
			padding: 3rem 0 3rem 2rem
			img
				margin-left: 0
			small
				font-size: 1.4rem
				display: inline-block
				// text-align: left
				margin-top: 2rem
				

