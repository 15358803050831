#testplatz__page
    #form
        #bullets
            margin-top: 3rem
        *
            text-align: left!important
        .form__header
            text-align: left
        .inputSection
            .inputGroup
                background: lighten($hellgrau, 10%)
                display: flex
                align-items: center
                flex-wrap: nowrap
                border-radius: 0.3rem
                border: 2px solid $hellgrau
                overflow: hidden
                &.radioGroup
                    border: none
              
                &.toggler
                    border: none
                    background: none
                    label
                        display: flex
                        padding: 0
                        background: none!important
                        border: none
                        &:before
                            content: none!important
                &.success
                    border-color: $success
                    border-width: 2px
                    &:before
                        border: none
                input
                    border-radius: 0
                    background: white
                    margin: 0
                    border: none
                label
                    background: $hellgrau
                    background: $hellgrau-bg
                    border-radius: 0
                    display: block
                    border: none
                    color: $black
                    vertical-align: middle
                    line-height: 1
                    padding: 2rem 2rem 1.6rem
                    position: relative
                    padding-left: 7rem
                    border: 2px solid $hellgrau
                    &:before
                        content: ""
                        position: absolute
                        height: 100%
                        top: 0
                        left: 0
                        width: 5rem
                        background: url('../img/icn-radio-unchecked.svg'), lighten($hellgrau-bg, 3%)
                        background-size: 2rem
                        background-position: center
                        background-repeat: no-repeat
                input[type="radio"]
                    display: none
                    &:checked
                        ~label
                            border-color: $rot!important
                            &:before
                                background: url('../img/icn-radio-checked.svg'), lighten($hellgrau-bg, 3%)
                                background-size: 2rem
                                background-position: center
                                background-repeat: no-repeat


       
    @media (min-width: $mobile)
        #form
            width: 100%
            max-width: 60rem
            margin: 0 auto
      
            .form__header
                margin-bottom: 3rem!important
                display: block
                .form--heading
                    font-size: 3rem!important
            .inputSection
                strong
                    font-size: 2rem
                
        #testplatz
            display: none
@media (min-width: $mobile)
    nav
        .logo_test
            width: 200px
            margin: 2rem auto!important
            display: block

// #testplatz__page
//     form
//         .form__header
//             text-align: center
//             *
//                 text-align: center
//                 font-family: $value

