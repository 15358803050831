.image--row
    display: grid
    grid-template-areas: 'b1 b3 b4 b5'
    grid-template-columns: repeat(4, 1fr)
    margin-bottom: 3rem
    @media (min-width: $mobile)
        grid-template-areas: 'b1 b2 b3 b4 b5'
        grid-template-columns: repeat(5, 1fr)
    img
        &:nth-child(1)
            grid-area: b1
        &:nth-child(2)
            display: none
            @media (min-width: $mobile)
                grid-area: b2
                display: block
        &:nth-child(3)
            grid-area: b3
        &:nth-child(4)
            grid-area: b4
        &:nth-child(5)
            grid-area: b5