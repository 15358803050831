.hersteller
    padding: 2rem
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: auto auto
    grid-template-areas: 'one two' 'three four'
    grid-gap: 2rem
    transition: all 300ms ease
    .hersteller__logo
        transition: all 300ms ease
        cursor: pointer
        background: #fff
        padding: 2rem
        display: flex
        align-items: center
        justify-content: center
        &:hover
            box-shadow: 0 0 1rem rgba($black, 0.15)
        &:nth-child(1)
            grid-area: one
        &:nth-child(2)
            grid-area: two
        &:nth-child(3)
            grid-area: three
        &:nth-child(4)
            grid-area: four
    @media (min-width: $mobile)
        grid-template-columns: repeat(4,1fr)
        grid-template-rows: auto
        grid-template-areas: 'one two three four'
