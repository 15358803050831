* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #1A1A1A;
}
*:focus {
  outline: none;
}

html, body {
  height: 100%;
  font-size: 10px;
  line-height: 1.4;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #DEDEDE;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    display: block;
  }
}

img {
  max-width: 100%;
}

.main__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  z-index: 0;
}

#mboxImported-default-target-global-mbox-0 {
  display: none !important;
}

@font-face {
  font-family: "value";
  src: url("../fonts/value/Value-Regular.woff2") format("woff2"), url("../fonts/value/Value-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "value";
  src: url("../fonts/value/Value-Bold.woff2") format("woff2"), url("../fonts/value/Value-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "univers";
  src: url("../fonts/univers/UniversLTStd.woff2") format("woff2"), url("../fonts/univers/UniversLTStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "univers";
  src: url("../fonts/univers/UniversLTStd-Bold.woff2") format("woff2"), url("../fonts/univers/UniversLTStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/noto/noto-sans-v27-latin-regular.eot");
  src: local(""), url("../fonts/noto/noto-sans-v27-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/noto/noto-sans-v27-latin-regular.woff2") format("woff2"), url("../fonts/noto/noto-sans-v27-latin-regular.woff") format("woff"), url("../fonts/noto/noto-sans-v27-latin-regular.ttf") format("truetype"), url("../fonts/noto/noto-sans-v27-latin-regular.svg#NotoSans") format("svg");
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/noto/noto-sans-v27-latin-500.eot");
  src: local(""), url("../fonts/noto/noto-sans-v27-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/noto/noto-sans-v27-latin-500.woff2") format("woff2"), url("../fonts/noto/noto-sans-v27-latin-500.woff") format("woff"), url("../fonts/noto/noto-sans-v27-latin-500.ttf") format("truetype"), url("../fonts/noto/noto-sans-v27-latin-500.svg#NotoSans") format("svg");
}
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/noto/noto-sans-v27-latin-italic.eot");
  src: local(""), url("../fonts/noto/noto-sans-v27-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/noto/noto-sans-v27-latin-italic.woff2") format("woff2"), url("../fonts/noto/noto-sans-v27-latin-italic.woff") format("woff"), url("../fonts/noto/noto-sans-v27-latin-italic.ttf") format("truetype"), url("../fonts/noto/noto-sans-v27-latin-italic.svg#NotoSans") format("svg");
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/noto/noto-sans-v27-latin-800.eot");
  src: local(""), url("../fonts/noto/noto-sans-v27-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/noto/noto-sans-v27-latin-800.woff2") format("woff2"), url("../fonts/noto/noto-sans-v27-latin-800.woff") format("woff"), url("../fonts/noto/noto-sans-v27-latin-800.ttf") format("truetype"), url("../fonts/noto/noto-sans-v27-latin-800.svg#NotoSans") format("svg");
}
* {
  font-family: "univers", sans-serif;
  font-weight: 400;
}

h1, h2 {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 2rem;
  font-family: "value", sans-serif;
  color: #C5003E;
}

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6;
}
@media (min-width: 840px) {
  h3 {
    font-size: 1.8rem;
  }
}

p, a {
  font-size: 1.6rem;
  line-height: 1.4;
}
@media (min-width: 840px) {
  p, a {
    font-size: 1.8rem;
  }
}

@media (min-width: 840px) {
  ul li {
    font-size: 1.8rem !important;
  }

  h1, h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2rem !important;
  }
}
a {
  color: black;
  font-weight: 600;
}

em {
  font-family: "univers", sans-serif;
}

strong {
  font-size: inherit;
  font-weight: 600;
}

.legal {
  font-size: 1.3rem;
}

.top-nav {
  width: 100%;
}
.top-nav .content {
  padding: 0.5rem 2rem 0;
  display: flex;
  justify-content: space-between;
}
.top-nav .content p {
  font-size: 1.2rem;
  color: #707070;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: #fff;
}
nav a {
  display: block;
  margin: 0;
  padding: 0;
}
nav .logo {
  width: 130px;
  display: block;
}
nav .kundenzufriedenheit {
  width: 80px;
}
@media (min-width: 840px) {
  nav .logo {
    width: 200px;
  }
  nav .phone__number {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  nav .phone__number span {
    font-family: "value", sans-serif;
    font-weight: bold;
    font-size: 3rem;
    color: #C5003E;
  }
  nav .phone {
    text-align: right;
    display: flex;
    align-items: center;
  }
  nav .phone .kundenzufriedenheit {
    margin-right: 3rem;
  }
  nav .phone small {
    min-width: 100%;
    font-size: 1.2rem;
    color: #707070;
  }
}

#header {
  padding: 0;
}

.header {
  display: grid;
  grid-template-areas: "image-1 zitat" "form form";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55vw auto;
  background: #efefef;
  height: auto;
  padding: 0;
}
@media (min-width: 840px) {
  .header {
    grid-template-rows: auto auto;
  }
}
.header.clean {
  grid-template-columns: 2fr 3fr;
  grid-template-areas: "image-1 zitat";
  align-items: center;
  padding: 2rem 0;
}
.header.clean .header__zitat {
  display: flex;
  justify-content: flex-end;
  padding: 3rem 0;
}
.header.clean .header__zitat .zitat--content {
  display: inline-block;
  padding: 2rem;
  background: white !important;
}
.header.berlin, .header.amplifon {
  grid-template-columns: 2fr 3fr;
  margin-bottom: 0 !important;
}
.header.berlin .header__image, .header.amplifon .header__image {
  grid-row-end: 4;
}
.header.berlin .header__zitat, .header.amplifon .header__zitat {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header.berlin .header__zitat.end, .header.amplifon .header__zitat.end {
  align-items: flex-end;
}
.header.berlin .header__zitat .zitat--content, .header.amplifon .header__zitat .zitat--content {
  padding-left: 0;
}
.header.berlin .header__zitat .zitat--content h2, .header.amplifon .header__zitat .zitat--content h2 {
  margin: 0;
  font-size: 2rem;
}
@media (min-width: 640px) {
  .header.berlin .header__zitat .zitat--content h2, .header.amplifon .header__zitat .zitat--content h2 {
    font-size: 3rem;
  }
}
@media (min-width: 840px) {
  .header.berlin, .header.amplifon {
    grid-template-columns: 1fr 1fr;
  }
  .header.berlin .header__zitat, .header.amplifon .header__zitat {
    display: block;
  }
  .header.berlin .header__zitat .zitat--content h2, .header.amplifon .header__zitat .zitat--content h2 {
    font-size: 4rem;
  }
}
.header.aktion {
  grid-template-columns: 21% 1fr;
  grid-template-rows: 55vw auto;
}
@media (min-width: 840px) {
  .header.aktion {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}
.header.aktion .header__image {
  height: 55vw;
}
@media (min-width: 840px) {
  .header.aktion .header__image {
    height: 100%;
  }
}
.header.aktion .header__zitat {
  padding-left: 0;
  justify-content: flex-end;
}
.header.aktion .header__zitat .zitat--content {
  justify-self: flex-end;
}
.header.aktion .header__zitat .zitat--content h2 {
  text-align: right;
  max-width: 60vw;
  font-size: 1.2rem;
  line-height: 1.2;
}
@media (min-width: 540px) {
  .header.aktion .header__zitat .zitat--content h2 {
    font-size: 2rem;
  }
}
@media (min-width: 840px) {
  .header.aktion .header__zitat .zitat--content h2 {
    padding-right: 2rem;
    max-width: 100%;
    text-align: center;
  }
}
.header__image {
  width: 100%;
  height: 50vw;
  position: relative;
  background-size: cover !important;
  grid-column: 1/-1;
  grid-column-end: 3;
  grid-row: 1;
  background: url("../img/header-waves.jpg") left 30% top 10% no-repeat;
}
.header__image.header__aktion {
  background: url("../img/header-aktion.jpg") left 30% top 10% no-repeat;
}
.header__image.clean {
  min-height: 100%;
  background-position: top left 30%;
}
@media (min-width: 840px) {
  .header__image.clean {
    min-height: 30rem;
    height: 40vw;
    max-height: 60rem;
  }
}
.header__image.berlin, .header__image.amplifon {
  background: url("../img/visual_corona.jpg") left 30% top 10% no-repeat;
}
@media (min-width: 640px) {
  .header__image {
    background-size: cover !important;
    background-position: center !important;
  }
}
.header__image.header--image-hoertester {
  background-image: url("../img/header-hoertester.jpg");
  background-size: 180% !important;
  background-position: left center !important;
}
@media (min-width: 840px) {
  .header__image.header--image-hoertester {
    background-size: cover !important;
  }
}
.header__zitat {
  grid-area: zitat;
  padding: 2rem;
  text-align: right;
}
.header__zitat img {
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
  text-align: right;
}
.header__zitat small {
  font-size: 1rem;
  color: #1A1A1A;
  text-align: right;
  margin-top: 1rem;
}
.header__zitat .main__button {
  margin-top: 3rem;
  width: auto;
  display: flex;
  align-items: center;
}
.header__zitat .main__button img {
  margin-bottom: 0;
}
.header__form {
  grid-area: form;
}
.header__form .form__header {
  display: block;
  padding: 1rem 2rem 3rem;
  text-align: center;
  background: #C5003E;
  position: relative;
}
.header__form .form__header:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 50%;
  height: 0rem;
  width: 0;
  border-left: 50vw solid white;
  border-bottom: 1.5rem solid white;
  border-right: 1.5rem solid transparent;
}
.header__form .form__header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 0rem;
  width: 0;
  border-right: 50vw solid white;
  border-bottom: 1.5rem solid white;
  border-left: 1.5rem solid transparent;
}
.header__form .form__header * {
  color: white !important;
  font-family: "value", sans-serif;
}
.header__form .form__content {
  padding: 0 2rem 2rem;
}
@media (min-width: 840px) {
  .header {
    grid-template-areas: "image-1 zitat" "image-1 form" "benefits form";
    margin-bottom: -6rem !important;
  }
  .header.clean {
    grid-template-areas: "image-1 zitat";
    grid-template-rows: auto;
    align-items: center;
    grid-template-columns: 50% 50%;
  }
  .header__image {
    height: auto;
    background-size: cover !important;
    background-position: left 40% center !important;
    grid-column: 1/-1;
    grid-column-end: 3;
    grid-row: 1/3;
  }
  .header__image.clean {
    background-position: top 20% left 30% !important;
    grid-row: 1;
  }
  .header__form {
    padding: 0 2rem;
  }
  .header__form form {
    background: #fff;
    z-index: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    height: 525px;
  }
  .header__form form #bullets {
    z-index: 10;
    position: relative;
  }
  .header__form form .form__content {
    height: 345px;
  }
  .header__benefits {
    grid-area: benefits;
    padding: 3rem;
  }
  .header__benefits ul li {
    font-weight: bold;
  }
  .header__zitat {
    text-align: left;
    padding: 3rem 0 3rem 2rem;
  }
  .header__zitat img {
    margin-left: 0;
  }
  .header__zitat small {
    font-size: 1.4rem;
    display: inline-block;
    margin-top: 2rem;
  }
}

footer {
  background: #fff;
  text-align: center;
}
footer .content {
  background: #efefef;
  padding: 3rem;
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  order: 6;
}
footer p {
  font-size: 1.2rem;
  display: block;
  margin: 1rem 0 1rem 2rem;
}
footer .legal--links {
  order: 3;
}
footer .legal--links a {
  margin: 0 2rem 0 0;
}
footer a {
  color: #C5003E;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 400;
  display: inline-block;
}
footer .phone {
  font-size: 1.6rem;
  font-family: "value", sans-serif;
  font-weight: bold;
  margin-top: 2rem;
}
footer .phone .phone__icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  footer .phone .phone__icon {
    width: auto;
    height: auto;
  }
}
@media (min-width: 900px) {
  footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  footer .phone {
    order: 2;
    font-size: 2rem;
    margin: 2rem 0;
  }
  footer .phone .phone__icon {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  footer p {
    font-size: 1.4rem;
  }
  footer p:first-child {
    order: 1;
  }
  footer p:last-child {
    order: 3;
  }
  footer p * {
    font-size: 1.4rem;
  }
}

#home footer {
  padding-bottom: 100px;
}
@media (min-width: 840px) {
  #home footer {
    padding-bottom: 150px;
  }
}

form {
  max-width: 100%;
  overflow-x: hidden;
  background: #fff;
  z-index: 0;
  position: relative;
}
form .form__header {
  margin-bottom: 2rem;
  text-decoration: none;
}
form .form__header .form--heading {
  color: #C5003E;
  margin-bottom: 0;
}
@media (min-width: 840px) {
  form .form__header .form--heading {
    font-size: 2rem !important;
  }
}
form .form__header h4 {
  color: #C5003E;
  font-size: 1.6rem;
  font-weight: 600;
}
form .form__header h4 span {
  font: inherit;
  color: inherit;
}
form .form__content .question {
  display: none;
}
form .form__content .question.active {
  display: block;
}
form .form__content strong {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 1.8rem;
  font-family: "value", sans-serif;
  text-align: center;
  line-height: 1.2;
}
form .form__content * {
  font-size: 1.6rem;
}
form .form__content .btn-outline {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  color: #C5003E;
  text-align: center;
  border: 2px solid #DEDEDE;
  border-radius: 10rem;
  transition: all 200ms ease;
  cursor: pointer;
  font-size: 1.8rem;
}
@media (min-width: 840px) {
  form .form__content .btn-outline:hover {
    border-color: #C5003E;
  }
}
form .form__content input[type=radio] {
  margin-bottom: 0;
  display: none;
}
form .form__content input[type=radio]:checked ~ .btn-outline {
  border-color: #C5003E;
}
form .form__content input[type=text], form .form__content input[type=number], form .form__content input[type=email], form .form__content input[type=password] {
  margin-bottom: 0;
  outline: none;
  border: 2px solid #DEDEDE;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 3rem;
  font-size: 1.8rem;
  text-align: center;
}
form .form__content input[type=text]:focus, form .form__content input[type=number]:focus, form .form__content input[type=email]:focus, form .form__content input[type=password]:focus {
  outline: none;
}
form .form__content .main__button {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
form .form__content .inputGroup {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
form .form__content .inputGroup .toggle {
  margin-right: 1rem;
}
form .form__content .inputGroup #check, form .form__content .inputGroup #checksession {
  display: none;
}
form .form__content .inputGroup #check:checked ~ label .toggle:after, form .form__content .inputGroup #checksession:checked ~ label .toggle:after {
  left: 2.2rem;
  background: #C5003E;
}
form .form__content .inputGroup.success input {
  border-color: #71AE73;
  color: #71AE73;
  display: block;
}
form .form__content .inputGroup.success:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: calc(100% - 4px);
  border-radius: 0 3rem 3rem 0;
  background: url("../img/icn-checkmark-success.svg") center no-repeat;
  background-size: 2rem;
  background-color: white;
  border: 2px solid #71AE73;
  border-left: none;
}
form .form__content .toggle_check {
  display: flex;
  align-items: center;
}
form .form__content .toggle_check span {
  font-size: 1.4rem;
  line-height: 1.4;
}
form #timeout--notice {
  text-align: center;
}
form #timeout--notice strong {
  display: inline-block;
  color: #71AE73;
  font-size: inherit !important;
  margin: 0;
}

.alert {
  font-weight: 700;
  color: #C5003E;
  margin: 1rem 0;
  text-align: center;
  display: block;
  min-width: 100%;
}

#bullets {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 380px;
  margin: 1rem auto;
  padding: 0 2rem 3rem;
}
#bullets .bullet {
  display: block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #707070;
  position: relative;
  transition: all 200ms ease;
}
#bullets .bullet:before {
  transition: all 200ms ease;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: #707070;
  opacity: 0;
}
#bullets .bullet:after {
  transition: all 200ms ease;
  right: 100%;
  content: "";
  position: absolute;
  width: 180%;
  height: 2px;
  z-index: -1;
  background: #707070;
  top: 50%;
  transform: translateY(-50%);
}
#bullets .bullet.activated {
  border: 2px solid #707070;
}
#bullets .bullet.activated:before {
  opacity: 1;
  background: #707070;
  transform: translate(-50%, -50%) scale(1);
}
#bullets .bullet.activated:after {
  background: #707070;
}
#bullets .bullet.activated:not(.disabled) {
  cursor: pointer;
}
#bullets .bullet.active {
  border: 2px solid #C5003E;
}
#bullets .bullet.active:before {
  opacity: 1;
  background: #C5003E;
  transform: translate(-50%, -50%) scale(1);
}
#bullets .bullet.active:after {
  background: #C5003E;
}
#bullets .bullet:first-child:after {
  display: none;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.text--success {
  margin: 2rem 0;
  display: block;
  color: #71AE73;
  font-weight: bold;
}

#form--modal {
  transition: all 500ms ease;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}
#form--modal .modal--content {
  background: white;
  padding: 2rem;
  width: 90%;
  max-width: 60rem;
  transition: inherit;
  transform: translateY(-20%);
}
#form--modal .modal--content p {
  margin-bottom: 2rem;
}
#form--modal.active {
  visibility: visible;
  opacity: 1;
}
#form--modal.active .modal--content {
  transform: translateY(0);
}

.logo--inputs {
  display: flex;
  flex-wrap: wrap;
}
.logo--inputs .inputGroup {
  flex: 1 0 45%;
}
.logo--inputs .inputGroup:nth-child(odd) {
  margin-right: 1rem;
}
.logo--inputs .inputGroup:last-child {
  margin-right: 0;
}
.logo--inputs .inputGroup .btn-outline {
  padding: 1rem !important;
}
.logo--inputs .inputGroup .btn-outline:before {
  content: none !important;
}
@media (min-width: 540px) {
  .logo--inputs .inputGroup .btn-outline {
    padding-left: 6rem !important;
  }
  .logo--inputs .inputGroup .btn-outline:before {
    content: "" !important;
  }
}
.logo--inputs .inputGroup .btn-outline img {
  max-width: 100%;
  height: auto;
}

#progress {
  margin: 4rem 0 1rem;
  background: #DEDEDE;
  width: 100%;
  height: 2rem;
  border: 1px solid #bdbdbd;
  position: relative;
}
#progress .progress--bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #C5003E;
  transition: width 300ms ease;
  width: 0;
}

#success--info {
  color: #71AE73;
  text-align: center !important;
}

@media (min-width: 840px) {
  .aktion #form {
    height: 540px;
  }
}
.login--form {
  padding: 2rem;
}

.anzeige {
  border: 1px solid #DEDEDE;
  display: block;
  text-decoration: none;
}
.anzeige__header {
  padding: 0.7rem 1rem;
  text-align: center;
  font-family: "univers", sans-serif;
  font-size: 1.3rem;
  color: #707070;
  border-bottom: 1px solid #DEDEDE;
}
.anzeige__body .button {
  display: block;
  text-decoration: none;
  background: #C5003E;
  color: white;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  font-family: "value", sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.anzeige__body h3 {
  font-family: "univers", sans-serif;
  font-weight: bold;
  text-align: right;
  font-size: 1.8rem;
  line-height: 1.3;
  max-width: 50%;
  float: right;
}
.anzeige__body-content .cta {
  padding: 0.5rem 1rem;
  font-family: "univers", sans-serif;
  font-size: 1.8rem;
}
.anzeige__body-content-image {
  padding: 2rem;
  width: 100%;
  height: 40vw;
  min-height: 250px;
  max-height: 500px;
}
.anzeige__body-content-image.image_step_1 {
  background: url("../img/Q1_visual_allgemein.jpg") center no-repeat;
  background-size: 220%;
}

#anzeige_multistep .anzeige {
  overflow: hidden;
}
#anzeige_multistep .anzeige__body-content-image {
  transform: translateX(-100vw);
  transition: transform 500ms ease;
}
#anzeige_multistep .anzeige__body-content-image h3 {
  transform: translateX(200vw);
  transition: transform 500ms ease;
}
#anzeige_multistep .anzeige__body-content .button {
  transform: translateY(100%);
  transition: transform 500ms ease;
}
#anzeige_multistep.animated .anzeige__body-content-image {
  transform: translateX(0);
}
#anzeige_multistep.animated .anzeige__body-content-image h3 {
  transform: translateX(0);
}
#anzeige_multistep.animated .anzeige__body-content .button {
  transform: translateY(0);
}

.cta__box {
  border: 1px solid #707070;
  padding: 1.5rem;
  background: #f8f8f8;
}
.cta__box h3 {
  line-height: 1.4;
  margin-bottom: 1rem;
}

ul.checklist, ul.steps {
  list-style-type: none;
  font-size: 1.6rem;
  margin: 2rem 0;
  font-family: "value", sans-serif;
}
ul.checklist li, ul.steps li {
  font-family: "value", sans-serif;
  font-weight: bold;
  margin-left: 3rem;
  position: relative;
  margin-bottom: 1rem;
}
ul.checklist li:before, ul.steps li:before {
  content: "";
  width: 23px;
  height: 26px;
  background: url("../img/icn-check.svg") center no-repeat;
  background-size: contain;
  position: absolute;
  left: -3rem;
  top: -4px;
}
ul.checklist.steps li, ul.steps.steps li {
  margin-left: 5rem;
  margin-bottom: 5rem;
}
ul.checklist.steps li:before, ul.steps.steps li:before {
  border: 5px solid white;
  content: "1";
  position: absolute;
  left: -5rem;
  top: 50%;
  transform: translateY(-50%);
  background: #707070;
  font-family: "value", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 2.4rem;
  text-align: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  line-height: 3.6rem;
  display: block;
}
ul.checklist.steps li:after, ul.steps.steps li:after {
  content: "";
  position: absolute;
  left: -2.8rem;
  top: 100%;
  height: 5rem;
  width: 3px;
  background: #707070;
  z-index: -1;
}
ul.checklist.steps li:first-child:before, ul.steps.steps li:first-child:before {
  background: url("../img/icn-checkmark.svg"), #C5003E;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
}
ul.checklist.steps li:nth-child(3):before, ul.steps.steps li:nth-child(3):before {
  content: "2";
}
ul.checklist.steps li:nth-child(4):before, ul.steps.steps li:nth-child(4):before {
  content: "3";
}
ul.checklist.steps li:nth-child(4):after, ul.steps.steps li:nth-child(4):after {
  display: none;
}

ul {
  margin: 1rem 0;
}
ul li {
  font-size: 1.6rem;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}

ol li {
  font-size: 1.8rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.phone__icon {
  margin-right: 1rem;
  position: relative;
  width: 2.5rem;
}

.download__icon {
  position: relative;
  margin-right: 1rem;
  top: 3px;
}

.icon__next {
  position: relative;
  margin-left: 1rem;
}

.main__button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  text-decoration: none;
  border-radius: 10rem;
  background: #C5003E;
  color: white;
  font-family: "value", sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 1rem 2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease;
  width: 100%;
  text-align: center;
}
.main__button .icn-right {
  margin-left: 1rem;
}
.main__button:after {
  transition: inherit;
  content: "";
  width: 0;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main__button:hover:after {
  width: 100vw;
  height: 100vw;
}
.main__button.small {
  width: auto;
  display: inline-block;
  margin: 0 1rem;
}
.main__button.white {
  color: #C5003E;
  width: auto;
  background: white;
  border: 2px solid #C5003E;
  display: inline-block;
  margin: 0 1rem;
}
.main__button.white:after {
  background: rgba(197, 0, 62, 0.15);
}
.main__button#submit_label {
  flex-direction: column;
}
.main__button#submit_label span {
  display: block;
  color: white;
  font-family: "value", sans-serif;
  font-weight: bold;
}
.main__button#submit_label .large {
  font-size: 1.8rem;
}
@media (min-width: 840px) {
  .main__button {
    font-size: 1.8rem;
  }
}

.main__button_content {
  display: block;
  text-decoration: none;
  border-radius: 10rem;
  background: #C5003E;
  color: white;
  font-family: "value", sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 1rem 2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease;
}
.main__button_content:after {
  transition: inherit;
  content: "";
  width: 0;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main__button_content:hover:after {
  width: 100vw;
  height: 100vw;
}
@media (min-width: 840px) {
  .main__button_content {
    font-size: 1.8rem;
  }
}

.toggle {
  width: 5rem;
  min-width: 5rem;
  height: 3rem;
  border: 2px solid #DEDEDE;
  border-radius: 50px;
  position: relative;
  display: block;
  cursor: pointer;
}
.toggle:after {
  content: "";
  position: absolute;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  background: #DEDEDE;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  transition: all 150ms ease;
}
.toggle.active:after {
  left: 2.2rem;
  background: #C5003E;
}

.info--holder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.info--button {
  display: inline-flex;
  width: 2.5rem;
  height: 2.5rem;
  background: #71AE73;
  font-family: Georgia, serif !important;
  margin-left: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.info--button:before {
  content: "";
  transition: transform 200ms ease;
  background: #71AE73;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.5;
  z-index: -1;
}
.info--button:hover:before {
  transform: translate(-50%, -50%) scale(1.3);
}

#glueckliche_kunden {
  background: #C5003E;
  color: white;
  padding: 3rem 2rem;
}
#glueckliche_kunden h3 {
  width: 100%;
  text-align: center;
  max-width: 80rem;
  display: block;
  margin: 0 auto;
  font-size: 2rem;
  color: white;
  font-family: "value", sans-serif;
  line-height: 1.4;
}

#hoergeraete {
  background: linear-gradient(to top, #b7bbba, #c3c4c6 20%, #f0f0f0 25%);
}
#hoergeraete .content {
  padding-bottom: 0;
}
#hoergeraete h1 {
  color: #C5003E;
}
@media (min-width: 840px) {
  #hoergeraete h1 {
    max-width: 400px;
    width: 50%;
  }
}
#hoergeraete img {
  display: block;
  width: 60vw;
  min-width: 25rem;
  margin: 0 auto;
}
@media (min-width: 840px) {
  #hoergeraete img {
    width: 100%;
    margin: 0 3rem 0 auto;
    margin-top: -12.5rem;
    max-width: 50vw;
  }
}
@media (min-width: 1200px) {
  #hoergeraete img {
    margin-top: -12.5rem;
    max-width: 70rem;
  }
}

#kennenlernpaket {
  background: #f0f0f0;
  cursor: pointer;
}
@media (min-width: 840px) {
  #kennenlernpaket .content, #kennenlernpaket .col--1 {
    flex: 1 0 45%;
  }
}
#kennenlernpaket h1 {
  color: #C5003E;
}
#kennenlernpaket .kennenlernpaket {
  display: grid;
  grid-template-areas: "p1 p2" "p3 p4" "p5 p5";
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  border: 1rem solid #C5003E;
}
#kennenlernpaket .kennenlernpaket .paket {
  position: relative;
}
#kennenlernpaket .kennenlernpaket .plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  line-height: 1;
  border-radius: 50%;
  background: #C5003E;
  border: 3px solid white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  font-size: 5rem;
  color: white;
  font-weight: bold;
  font-family: "value", sans-serif;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 2;
}
#kennenlernpaket .kennenlernpaket .paket-1 .plus {
  top: 100%;
}
@media (min-width: 540px) {
  #kennenlernpaket .kennenlernpaket .paket-1 .plus {
    top: 50%;
  }
}
#kennenlernpaket .kennenlernpaket .paket-2 .plus, #kennenlernpaket .kennenlernpaket .paket-3 .plus, #kennenlernpaket .kennenlernpaket .paket-4 .plus {
  display: none;
}
@media (min-width: 540px) {
  #kennenlernpaket .kennenlernpaket .paket-2 .plus, #kennenlernpaket .kennenlernpaket .paket-3 .plus, #kennenlernpaket .kennenlernpaket .paket-4 .plus {
    display: flex;
  }
}
#kennenlernpaket .kennenlernpaket .paket-5 img {
  width: 50%;
  display: block;
  margin: 0 auto;
}
#kennenlernpaket .kennenlernpaket .paket-5 .plus {
  left: 50%;
  top: 0;
  right: auto;
  transform: translate(-50%, -50%);
}
@media (min-width: 540px) {
  #kennenlernpaket .kennenlernpaket {
    grid-template-areas: "p1 p2 p3 p4" ". p5 p5 .";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
  }
}
@media (min-width: 840px) {
  #kennenlernpaket .kennenlernpaket {
    grid-template-areas: "p1 p2 p3 p4 p5";
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
  }
  #kennenlernpaket .kennenlernpaket .paket-5 img {
    width: 100%;
  }
  #kennenlernpaket .kennenlernpaket .paket-5 .plus {
    top: 50%;
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
  }
}
#kennenlernpaket .kennenlernpaket .paket-1 {
  grid-area: p1;
}
#kennenlernpaket .kennenlernpaket .paket-2 {
  grid-area: p2;
}
#kennenlernpaket .kennenlernpaket .paket-3 {
  grid-area: p3;
}
#kennenlernpaket .kennenlernpaket .paket-4 {
  grid-area: p4;
}
#kennenlernpaket .kennenlernpaket .paket-5 {
  grid-area: p5;
}
#kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket {
  display: grid;
  grid-template-areas: "p1 p2 p3 p4" ". p5 p5 .";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
}
@media (max-width: 539px) {
  #kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .plus {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
  }
}
#kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-1 .plus {
  top: 50%;
}
#kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-2 .plus, #kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-3 .plus, #kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-4 .plus {
  display: flex;
}
#kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-5 img {
  width: 50%;
  display: block;
  margin: 0 auto;
}
#kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-5 .plus {
  left: 50%;
  top: 0;
  right: auto;
  transform: translate(-50%, -50%);
}
@media (min-width: 840px) {
  #kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket {
    grid-template-areas: "p1 p2 p3 p4 p5";
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
  }
  #kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-5 img {
    width: 100%;
  }
  #kennenlernpaket.kennenlernpaket--geschenk .kennenlernpaket .paket-5 .plus {
    top: 50%;
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
  }
}

#kundenzufriedenheit h2 {
  color: #C5003E;
}
#kundenzufriedenheit h3 {
  font-size: 1.8rem;
  line-height: 1.4;
}
#kundenzufriedenheit .col-1 {
  margin-bottom: 3rem;
}
@media (min-width: 840px) {
  #kundenzufriedenheit {
    display: flex;
  }
  #kundenzufriedenheit .col-1 {
    flex: 1 0 50%;
    max-width: 50%;
  }
  #kundenzufriedenheit .col-1:first-child {
    padding-right: 3rem;
  }
}

.kundenzitate .google {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.kundenzitate .google img {
  margin-left: 1rem;
}
.kundenzitate .bewertung {
  background: #efefef;
  padding: 2rem;
  text-align: center;
}
@media (min-width: 840px) {
  .kundenzitate .bewertung {
    margin: 0 1rem;
  }
}
.kundenzitate .bewertung p {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.kundenzitate .bewertung__name {
  font-style: normal;
  color: #707070;
  font-size: 1.1rem;
}
.kundenzitate .bewertung__sterne {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.6rem;
}
.kundenzitate .bewertung__sterne img {
  width: 1.6rem;
  margin: 0 0.1rem;
}

#bewertungen {
  margin-left: 2rem;
  margin-right: 2rem;
  position: relative;
}
#bewertungen .prevArrow, #bewertungen .nextArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
#bewertungen .prevArrow {
  left: -2rem;
}
#bewertungen .nextArrow {
  right: -2rem;
}

#warum .content {
  padding-bottom: 1rem;
}
@media (min-width: 840px) {
  #warum {
    display: flex;
    align-items: center;
  }
  #warum img {
    flex: 1 0 50%;
    max-width: 50%;
  }
  #warum .content {
    max-width: 50%;
    flex: 1 0 50%;
  }
}

.video__wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.video__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hersteller {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: "one two" "three four";
  grid-gap: 2rem;
  transition: all 300ms ease;
}
.hersteller .hersteller__logo {
  transition: all 300ms ease;
  cursor: pointer;
  background: #fff;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hersteller .hersteller__logo:hover {
  box-shadow: 0 0 1rem rgba(26, 26, 26, 0.15);
}
.hersteller .hersteller__logo:nth-child(1) {
  grid-area: one;
}
.hersteller .hersteller__logo:nth-child(2) {
  grid-area: two;
}
.hersteller .hersteller__logo:nth-child(3) {
  grid-area: three;
}
.hersteller .hersteller__logo:nth-child(4) {
  grid-area: four;
}
@media (min-width: 840px) {
  .hersteller {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "one two three four";
  }
}

#dynamic__footer {
  text-decoration: none;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 100%);
  bottom: 0;
  width: 100%;
  height: 100px;
  background: #C5003E;
  text-align: center;
  cursor: pointer;
  transition: 500ms ease;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#dynamic__footer h2, #dynamic__footer h4 {
  color: white;
  margin-bottom: 0;
  font-family: "value", sans-serif;
}
#dynamic__footer h2 span, #dynamic__footer h4 span {
  font: inherit;
  color: inherit;
}
#dynamic__footer h4 {
  font-weight: bold;
}
#dynamic__footer.active {
  transform: translate(-50%, 0);
}
@media (min-width: 840px) {
  #dynamic__footer {
    height: 150px;
  }
  #dynamic__footer img {
    width: 3rem;
  }
}

#loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  text-align: left;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
#loader.loading {
  display: flex;
}
#loader.loaded {
  display: none;
}
#loader .found {
  display: none;
  text-align: center;
}
@media (min-width: 840px) {
  #loader {
    background: rgba(0, 0, 0, 0.9);
    z-index: 999999;
  }
  #loader .loader__content {
    width: 500px;
    background: #fff;
    padding: 3rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  #loader .loader__content .kunden_overlay {
    margin: 2rem auto 0;
    display: block;
  }
  #loader .loader__content .loader_logo {
    width: 220px;
    margin: 0 auto 2rem;
  }
  #loader .loader__content #close_button {
    font-weight: 400;
  }
  #loader .loader__content .loader {
    text-align: center;
  }
  #loader .loader__content .list {
    width: 100%;
  }
  #loader .loader__content h2 {
    font-size: 2rem;
    max-width: 400px;
  }
  #loader .loader__content .found {
    display: none;
  }
  #loader .loader__content .found #close_button {
    text-decoration: underline;
    margin: 2rem 0;
    display: block;
    cursor: pointer;
  }
  #loader .loader__content .found.active {
    display: block;
  }
  #loader .loader__content .found.active .main__button {
    margin: 2rem 0;
  }
  #loader .loader__content .found.active h3 {
    color: #71AE73;
  }
  #loader .loader__content .found.active svg {
    width: 50px;
    height: 50px;
  }
  @supports not (-ms-high-contrast: none) {
    #loader .loader__content .found.active svg #kreis {
      animation: draw 1s forwards ease;
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
    }
    #loader .loader__content .found.active svg #check1, #loader .loader__content .found.active svg #check2 {
      animation: draw 1s forwards ease;
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
    }
    #loader .loader__content .found.active svg #check1 {
      animation-delay: 500ms;
    }
    #loader .loader__content .found.active svg #check2 {
      animation-delay: 600ms;
    }
  }
}

#testplatz {
  text-align: center;
  margin-bottom: 2rem;
}
#testplatz h3 {
  font-family: "value", sans-serif;
}
#testplatz .checkbox svg {
  width: 5rem;
}

.spinner__loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(197, 0, 62, 0.2);
  border-radius: 50%;
  margin: 2rem auto;
  position: relative;
  animation: rotate 1s linear infinite forwards;
}
.spinner__loader:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #C5003E;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes draw {
  0% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.vorsorge--icon {
  width: 15rem;
  height: 15rem;
  display: block;
  margin: 2rem auto;
  padding-top: 2rem;
}

.benefits {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #C5003E;
}
.benefits .benefit {
  flex: 1 0 0;
  border-right: 1px solid #DEDEDE;
  text-align: center;
  padding: 0 1rem;
}
.benefits .benefit:last-child {
  border-right: none;
}
.benefits .benefit img {
  display: block;
  margin: 0 auto 1rem;
}
.benefits .benefit small {
  font-size: 1rem;
  line-height: 1.4;
  color: #707070;
}
@media (min-width: 840px) {
  .benefits .benefit img {
    width: auto;
    height: 4rem;
  }
  .benefits .benefit small {
    font-size: 1.4rem;
  }
}
.benefits.large {
  flex-wrap: wrap;
}
.benefits.large .benefit {
  flex: 1 0 40%;
  margin-bottom: 2rem;
}
@media (min-width: 840px) {
  .benefits.large {
    flex-wrap: nowrap;
  }
  .benefits.large .benefit {
    margin-bottom: 0;
    flex: 1 0 0;
  }
}

.cookie--badge {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C5003E;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .cookie--badge {
    z-index: 20;
  }
}

@media (max-width: 600px) {
  .CybotCookiebotDialogBodyLevelButtonWrapper {
    display: block !important;
  }
}
#CybotCookiebotDialogDetailFooter a {
  font-size: inherit !important;
}

#CybotCookiebotDialogDetailBodyContentTabs a {
  font-size: 1.2rem !important;
}

#CybotCookiebotDialog {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 90% !important;
  max-width: 61rem !important;
  box-shadow: 0 0 100vw 100vw rgba(0, 0, 0, 0.5) !important;
  padding: 2rem !important;
  box-sizing: border-box !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsButton {
  font-weight: normal !important;
  text-decoration: none !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBody {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
#CybotCookiebotDialog #CybotCookiebotDialogPoweredbyLink {
  display: none;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
  padding: 0;
  padding-bottom: 2rem;
}
#CybotCookiebotDialog * {
  font-family: "univers", sans-serif !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelWrapper {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane {
  padding: 0 !important;
  border: none !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsWrapper {
  padding: 0 !important;
  background: white !important;
  border: none !important;
  text-align: right !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  order: 2;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  display: flex !important;
  padding: 1rem 2rem !important;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0 1rem !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  background: white !important;
  border: none !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  color: #707070 !important;
  margin-left: 0 !important;
  justify-content: flex-start;
  font-weight: normal !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background: #C5003E !important;
  border: 1px solid #C5003E !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtons {
  margin: 0 !important;
  width: 100%;
}

.cookie-banner-close button {
  padding: 0;
  text-align: right;
  font-size: 1.2rem;
  background: transparent;
  border: none;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 22px;
  width: 100%;
  display: block;
}
@media screen and (min-width: 800px) {
  .cookie-banner-close button {
    width: auto;
  }
}
@media screen and (max-width: 800px) {
  .cookie-banner-close button {
    padding: 0;
    border: none;
    text-align: right;
    text-decoration: underline;
    font-size: 1.2rem;
    margin-left: auto;
  }
}

.cookie-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.cookie-banner-content p,
.cookie-content, .cookie-banner-content div a {
  font-size: 1.2rem !important;
  color: #ffffff;
  width: 100%;
  display: inline !important;
}
@media screen and (min-width: 1200px) {
  .cookie-banner-content p,
.cookie-content, .cookie-banner-content div a {
    margin-top: 5px;
    margin-bottom: 0;
    display: block;
  }
}

@media (max-width: 600px) {
  .cookie-content {
    max-width: 100%;
  }
}

.cookie-banner-wrapper {
  max-width: 1200px;
}

.cookie-banner-overContent .cookie-banner {
  z-index: 88888;
}

.cookie-banner-buttons {
  display: flex;
}

.cookie-banner-content {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .cookie-banner-content {
    flex-direction: column;
  }
}

.cookie-banner-content div a {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
}

.cookie-banner-close {
  display: inline;
  font-size: 1.2rem;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}

.cookie-banner-details {
  line-height: 0 !important;
}

.cookie-banner {
  display: none;
  z-index: 9999;
  background: #444444;
  border-top: 1px solid #ffffff;
  position: fixed;
  width: 100%;
  max-width: 1200px;
  bottom: 0;
  padding: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.cookie-banner-wrapper {
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inaktiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.inaktiv img {
  margin-bottom: 2rem;
}
.inaktiv .buttons {
  margin-top: 2rem;
}
@media (max-width: 600px) {
  .inaktiv .buttons .main__button {
    margin-bottom: 2rem;
    display: block;
  }
}
.inaktiv h1 {
  color: black;
}

#modal, #lightbox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.95);
  padding: 2rem;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal .modal--content, #lightbox .modal--content {
  padding: 2rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
  max-width: 60rem;
  font-size: 1.6rem;
  position: relative;
}
#modal .modal--content h3, #modal .modal--content p, #modal .modal--content strong, #lightbox .modal--content h3, #lightbox .modal--content p, #lightbox .modal--content strong {
  text-align: center;
}
#modal .modal--content img, #lightbox .modal--content img {
  display: block;
  margin: 0 auto;
}
#modal .close, #lightbox .close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  display: inline-block;
  margin-left: auto;
  text-align: right;
  cursor: pointer;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #C5003E;
}

.slick-track {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.slick-track .slick-slide {
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#corona {
  background: #C5003E;
}
#corona .content {
  padding: 1rem;
}
#corona * {
  color: white;
}
#corona h2 {
  text-align: center;
  margin: 0;
}
@media (min-width: 840px) {
  #corona h2 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}
#corona .corona--grid {
  display: grid;
  align-items: center;
  grid-template-areas: "text text text text" "desinfektion waschen handschuh mundschutz";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
}
#corona .corona--grid .grid--item {
  text-align: center;
}
#corona .corona--grid .grid--item img {
  max-width: 8rem;
}
#corona .corona--grid .grid--item:nth-child(1) {
  grid-area: desinfektion;
}
#corona .corona--grid .grid--item:nth-child(2) {
  grid-area: waschen;
}
#corona .corona--grid .grid--item:nth-child(3) {
  grid-area: text;
}
#corona .corona--grid .grid--item:nth-child(4) {
  grid-area: handschuh;
}
#corona .corona--grid .grid--item:nth-child(5) {
  grid-area: mundschutz;
}
@media (min-width: 640px) {
  #corona .corona--grid {
    grid-template-areas: "desinfektion waschen text handschuh mundschutz";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.testplatz--page #corona--image .corona--3-grid {
  display: grid;
  grid-template-areas: "bild1 bild2" "text text";
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
}
.testplatz--page #corona--image .corona--3-grid .grid--item {
  background: #C5003E;
}
.testplatz--page #corona--image .corona--3-grid .grid--item:nth-child(1) {
  grid-area: bild1;
}
.testplatz--page #corona--image .corona--3-grid .grid--item:nth-child(2) {
  grid-area: bild2;
}
.testplatz--page #corona--image .corona--3-grid .grid--item:nth-child(3) {
  grid-area: text;
  padding: 3rem 1rem;
}
.testplatz--page #corona--image .corona--3-grid .grid--item:nth-child(3) * {
  color: white;
  text-align: center;
}
@media (min-width: 840px) {
  .testplatz--page #corona--image .corona--3-grid {
    grid-template-areas: "bild1 text bild2";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  .testplatz--page #corona--image .corona--3-grid .grid--item:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
  }
}

.image--row {
  display: grid;
  grid-template-areas: "b1 b3 b4 b5";
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 3rem;
}
@media (min-width: 840px) {
  .image--row {
    grid-template-areas: "b1 b2 b3 b4 b5";
    grid-template-columns: repeat(5, 1fr);
  }
}
.image--row img:nth-child(1) {
  grid-area: b1;
}
.image--row img:nth-child(2) {
  display: none;
}
@media (min-width: 840px) {
  .image--row img:nth-child(2) {
    grid-area: b2;
    display: block;
  }
}
.image--row img:nth-child(3) {
  grid-area: b3;
}
.image--row img:nth-child(4) {
  grid-area: b4;
}
.image--row img:nth-child(5) {
  grid-area: b5;
}

.content {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 2rem;
}
@media (min-width: 840px) {
  .content {
    padding: 3rem;
  }
}

.row__image {
  margin-bottom: 2rem;
}

.date {
  font-size: 1.3rem;
  font-family: "univers", sans-serif;
  color: #707070;
  font-style: normal;
  display: block;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
}
.date:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 150px;
  height: 1px;
  background: #707070;
}
.date strong {
  font-weight: 700;
  font-family: inherit;
}

.grey {
  background: #f8f8f8;
}

.mobilefull {
  padding: 0;
}

.lightgrey {
  background: #efefef;
}

.legal {
  color: #707070;
  margin-top: 2rem;
}

.desktop {
  display: none !important;
}
@media (min-width: 840px) {
  .desktop {
    display: block !important;
  }
}

@media (min-width: 840px) {
  .mobile {
    display: none !important;
  }
}

#danke h1 {
  color: #C5003E;
  position: relative;
  margin-left: 4.5rem;
}
#danke h1:before {
  content: "";
  position: absolute;
  left: -4.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: url("../img/icn-checkmark.svg") center no-repeat, #C5003E;
  background-size: 2.3rem;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  display: block;
}
#danke hr {
  width: 100%;
  border: none;
  height: 1px;
  background: #DEDEDE;
  margin: 1rem 0 2rem;
}
#danke .buchungsnummer {
  color: #C5003E;
}
#danke h2 {
  color: #1A1A1A;
  pointer-events: none;
  text-decoration: none;
}
@media (min-width: 840px) {
  #danke {
    padding: 4rem;
    display: flex;
  }
  #danke hr {
    display: none;
  }
  #danke .col-1 {
    flex: 1 0 0;
    padding-right: 3rem;
  }
  #danke .col-1 h2 {
    font-size: 3rem;
  }
  #danke .col-1 h2 .phone__icon {
    width: 3rem;
    height: 3rem;
  }
  #danke .col-2 {
    padding: 2rem;
    border: 1px solid #C5003E;
    background: #fff;
  }
  #danke .col-2 h2 {
    font-size: 3rem;
  }
  #danke .main__button_content {
    display: inline-block;
  }
}

#schritte h2 {
  color: #1A1A1A;
  margin-bottom: 0;
}
@media (min-width: 840px) {
  #schritte {
    padding: 4rem;
  }
}

#testplatz__page #form #bullets {
  margin-top: 3rem;
}
#testplatz__page #form * {
  text-align: left !important;
}
#testplatz__page #form .form__header {
  text-align: left;
}
#testplatz__page #form .inputSection .inputGroup {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 0.3rem;
  border: 2px solid #DEDEDE;
  overflow: hidden;
}
#testplatz__page #form .inputSection .inputGroup.radioGroup {
  border: none;
}
#testplatz__page #form .inputSection .inputGroup.toggler {
  border: none;
  background: none;
}
#testplatz__page #form .inputSection .inputGroup.toggler label {
  display: flex;
  padding: 0;
  background: none !important;
  border: none;
}
#testplatz__page #form .inputSection .inputGroup.toggler label:before {
  content: none !important;
}
#testplatz__page #form .inputSection .inputGroup.success {
  border-color: #71AE73;
  border-width: 2px;
}
#testplatz__page #form .inputSection .inputGroup.success:before {
  border: none;
}
#testplatz__page #form .inputSection .inputGroup input {
  border-radius: 0;
  background: white;
  margin: 0;
  border: none;
}
#testplatz__page #form .inputSection .inputGroup label {
  background: #DEDEDE;
  background: #efefef;
  border-radius: 0;
  display: block;
  border: none;
  color: #1A1A1A;
  vertical-align: middle;
  line-height: 1;
  padding: 2rem 2rem 1.6rem;
  position: relative;
  padding-left: 7rem;
  border: 2px solid #DEDEDE;
}
#testplatz__page #form .inputSection .inputGroup label:before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 5rem;
  background: url("../img/icn-radio-unchecked.svg"), #f7f7f7;
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
}
#testplatz__page #form .inputSection .inputGroup input[type=radio] {
  display: none;
}
#testplatz__page #form .inputSection .inputGroup input[type=radio]:checked ~ label {
  border-color: #C5003E !important;
}
#testplatz__page #form .inputSection .inputGroup input[type=radio]:checked ~ label:before {
  background: url("../img/icn-radio-checked.svg"), #f7f7f7;
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 840px) {
  #testplatz__page #form {
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
  }
  #testplatz__page #form .form__header {
    margin-bottom: 3rem !important;
    display: block;
  }
  #testplatz__page #form .form__header .form--heading {
    font-size: 3rem !important;
  }
  #testplatz__page #form .inputSection strong {
    font-size: 2rem;
  }
  #testplatz__page #testplatz {
    display: none;
  }
}

@media (min-width: 840px) {
  nav .logo_test {
    width: 200px;
    margin: 2rem auto !important;
    display: block;
  }
}
.vorsorgewochen {
  max-width: 20rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  max-height: 50%;
  height: auto;
}
@media (min-width: 840px) {
  .vorsorgewochen {
    left: 2rem;
    bottom: 2rem;
    right: auto;
    top: auto;
  }
}

.payback--page nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payback--page nav .payback--logo, .payback--page nav .amplifon--logo {
  width: 120px;
}
@media (min-width: 840px) {
  .payback--page nav .payback--logo, .payback--page nav .amplifon--logo {
    width: 200px;
  }
}
.payback--page #version--payback {
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "b1 b2 b3 b4";
}
.payback--page #version--payback img:nth-child(2) {
  display: block;
}

#hoertester #hoergeraete {
  background: #efefef;
}
#hoertester #hoergeraete .content {
  padding-bottom: 3rem;
}
#hoertester footer {
  padding-bottom: 100px;
}
@media (min-width: 840px) {
  #hoertester footer {
    padding-bottom: 150px;
  }
}

#kundenzufriedenheit .soft--row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
#kundenzufriedenheit .soft--row .ohr {
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  border-radius: 50%;
  background: url(../img/ohr.jpg) center;
  background-size: auto;
  background-size: cover;
  display: inline-block;
  margin-right: 2rem;
}
#kundenzufriedenheit .soft--row h2 {
  margin-bottom: 0;
}

#mini {
  background: white;
}
#mini nav {
  padding: 0;
  border-bottom: 1px solid #DEDEDE;
}
#mini nav .nav--content {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: #fff;
}
#mini #breadCrumb {
  padding: 40px 0px 20px 0px;
}
#mini #breadCrumb a {
  color: grey;
  font-size: 1.4rem;
  font-weight: 100 !important;
  text-decoration: underline;
}
#mini #breadCrumb a:hover {
  color: #C5003E;
}
#mini #breadCrumb img {
  height: 8px;
  margin: 0px 5px;
}
@media (max-width: 700px) {
  #mini #breadCrumb {
    display: none;
  }
}
#mini .content--container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}
#mini .img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 150px;
  height: 150px;
}
#mini .image-zoom-result {
  border: 1px solid #d4d4d4;
  width: 300px;
  height: 300px;
  position: absolute;
}
#mini .zoom-box {
  width: 100%;
  height: 100%;
  position: relative;
  transition: none;
  border-radius: 8px;
}
#mini .zoom-box * {
  transition: none;
}
#mini #product {
  display: flex;
  margin-bottom: 60px;
  flex-wrap: wrap;
}
#mini #product .productGallery {
  display: inline-flex;
  flex: 2 0 0;
  flex-wrap: wrap;
}
@media (max-width: 700px) {
  #mini #product .productGallery {
    max-width: 100%;
  }
  #mini #product .productGallery .sidePictures {
    flex-direction: row;
    order: 2;
    margin-right: 0px !important;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #mini #product .productGallery .bigPicture {
    min-width: 100%;
    order: 1;
    margin-bottom: 30px;
    max-height: 350px;
  }
}
@media (max-width: 380px) {
  #mini #product .productGallery .bigPicture {
    max-height: 330px;
  }
}
@media (max-width: 320px) {
  #mini #product .productGallery .bigPicture {
    max-height: 250px;
  }
}
#mini #product .productGallery .sidePictures {
  flex: 1 0 0;
  flex-direction: column;
  margin-right: 20px;
}
#mini #product .productGallery .sidePictures .smallPicture {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  height: 102px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 8px;
  display: block;
}
#mini #product .productGallery .sidePictures .smallPicture img {
  height: 100%;
  border-radius: 8px;
  display: block;
  width: 100%;
}
#mini #product .productGallery .sidePictures .smallPicture:hover {
  opacity: 0.7;
}
#mini #product .productGallery .sidePictures .smallPicture:last-child {
  margin-bottom: 0px;
}
#mini #product .productGallery .bigPicture {
  background-position: center;
  background-size: cover;
  flex: 6 0 0;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  margin-right: 40px;
  height: 468px;
  position: relative;
}
#mini #product .productGallery .bigPicture #zoomHinweis {
  margin: 8px 0 0 8px;
  font-size: 1.2rem;
  color: grey;
}
#mini #product .productGallery .bigPicture #expandedImg {
  display: block;
  position: relative;
  top: -10%;
}
@media (max-width: 1060px) {
  #mini #product .productGallery .bigPicture #expandedImg {
    top: 0;
  }
}
@media (max-width: 960px) {
  #mini #product .productGallery .bigPicture #expandedImg {
    top: -25%;
  }
}
@media (max-width: 800px) {
  #mini #product .productGallery .bigPicture #expandedImg {
    top: -15%;
  }
}
@media (max-width: 600px) {
  #mini #product .productGallery .bigPicture #expandedImg {
    top: 0;
  }
}
#mini #product .productGallery .bigPicture #topProduct {
  z-index: 999;
  position: absolute;
  top: 50px;
  right: 0px;
  background: #232F3E;
  padding: 5px 10px 2px;
}
#mini #product .productGallery .bigPicture #topProduct:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 33px 0;
  border-color: transparent #232F3E transparent transparent;
  position: absolute;
  left: -12px;
  top: 0;
}
#mini #product .productGallery .bigPicture #topProduct p strong {
  color: white;
}
#mini #product .productGallery .bigPicture #topProduct span {
  color: #C5003E;
  font-weight: bold;
}
#mini #product .productDescription {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-self: center;
}
@media (max-width: 960px) {
  #mini #product .productDescription {
    min-width: 100%;
  }
}
#mini #product .productDescription h1 {
  margin-bottom: 1rem;
}
#mini #product .productDescription h3 {
  margin-bottom: 40px;
}
#mini #product .productDescription h4 {
  color: #C5003E;
  font-weight: bold;
  font-size: 1.6rem;
}
#mini #product .productDescription .checklist li {
  font-weight: 400;
}
@media (max-width: 960px) {
  #mini #product .productDescription {
    padding-top: 30px;
  }
  #mini #product .productDescription #topProduct {
    left: -20px;
    margin-top: 30px;
    display: block;
  }
}
@media (max-width: 960px) {
  #mini #product .productGallery {
    margin-bottom: 40px;
  }
  #mini #product .productGallery .bigPicture {
    margin-right: 0px;
  }
}
#mini #iframeCenter {
  margin: 0 auto;
  width: 1000px;
}
#mini #iframeCenter:before {
  content: "";
  position: absolute;
  z-index: 1;
  display: block;
  right: 0;
  height: 120px;
  width: 220px;
  background: white;
}
@media (max-width: 1000px) {
  #mini #iframeCenter {
    display: none;
  }
}
@media (max-width: 450px) {
  #mini .btn-large {
    font-size: 1.6rem;
  }
}
#mini .productGalleryMobile {
  width: 100%;
  overflow: hidden;
  display: none;
  position: relative;
  margin-top: 2rem;
}
#mini .productGalleryMobile .mobile-slider-wrapper .slick-list.draggable {
  border-radius: 7px;
}
#mini .productGalleryMobile .mobile-slider-wrapper .slides-mini {
  width: 100vw;
  overflow: hidden;
}
#mini .productGalleryMobile .mobile-slider-wrapper .slides-mini img {
  height: auto;
  width: 100%;
}
#mini .productGalleryMobile #topProduct {
  z-index: 999;
  position: absolute;
  top: 50px;
  right: 0px;
  background: #232F3E;
  padding: 5px 10px 2px;
}
#mini .productGalleryMobile #topProduct p strong {
  color: white;
}
@media (max-width: 760px) {
  #mini .productGalleryMobile #topProduct {
    top: 20px;
  }
}
#mini .productGalleryMobile #topProduct:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 33px 0;
  border-color: transparent #232F3E transparent transparent;
  position: absolute;
  left: -12px;
  top: 0;
}
#mini .productGalleryMobile #topProduct p strong {
  color: white;
}
#mini .productGalleryMobile #topProduct span {
  color: #C5003E;
}
@media (max-width: 700px) {
  #mini .productGalleryMobile {
    display: block;
  }
}
#mini .productGallery {
  display: inline-flex !important;
}
@media (max-width: 700px) {
  #mini .productGallery {
    display: none !important;
  }
}
#mini .i-right-angle {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  cursor: pointer;
}
#mini .i-left-angle {
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 9999;
  height: 30px;
  cursor: pointer;
}
#mini #form--container {
  position: relative;
  border: 2px solid #C5003E;
  z-index: 999;
  background: #fff;
  height: 625px;
}
@media (max-width: 500px) {
  #mini #form--container {
    height: 670px;
  }
}
#mini #form--container .formHeader {
  background: linear-gradient(to right, #C5003E, rgb(85, 15, 80));
  padding: 10px 10px 50px;
  text-align: center;
  position: relative;
}
#mini #form--container .formHeader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 25px;
  background: url("../img/abdecker.svg") center no-repeat;
  background-size: cover;
}
@media (min-width: 670px) and (max-width: 980px) {
  #mini #form--container .formHeader:before {
    height: 35px;
  }
}
#mini #form--container .formHeader h2 {
  color: white;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 15px;
}
#mini #form--container .formHeader h2 span {
  font-size: inherit;
  color: white;
  line-height: inherit;
}
#mini #form--container .formHeader h4 {
  color: white;
}
#mini #form--container .formHeader h4 span {
  color: inherit;
}
#mini #form--container .formContent {
  padding: 20px;
  max-width: 380px;
  margin: 0 auto;
  background: #fff;
  height: 70%;
}
@media (max-width: 500px) {
  #mini #form--container .formContent {
    height: 68%;
  }
}
#mini #form--container .formContent .question {
  display: none;
  text-align: center;
}
#mini #form--container .formContent .question.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#mini #form--container .formContent .question input[type=radio] {
  display: none;
}
#mini #form--container .formContent .question input[type=radio] ~ label {
  cursor: pointer;
}
#mini #form--container .formContent .question strong {
  font-size: 2rem;
  line-height: 1.3;
}
#mini #form--container .formContent .question p {
  font-size: 1.6rem;
}
#mini #kundenzufriedenheit .soft--row .ohr {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C5003E !important;
  padding: 1rem;
}
#mini #dynamic__footer {
  max-width: 100%;
}
#mini footer {
  padding-bottom: 100px;
}
@media (min-width: 840px) {
  #mini footer {
    padding-bottom: 150px;
  }
}
#mini footer .content {
  max-width: 100%;
}
#mini footer .content--container {
  border-top: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 960px) {
  #mini footer .content--container {
    display: block;
  }
}

#mini .logo_test {
  margin: 2rem auto !important;
}

#mini.testplatz--page footer {
  padding-bottom: 0 !important;
}

.alert {
  color: #C5003E;
  padding: 5px;
  text-align: center;
  margin-top: 1rem;
}

.formActive {
  display: block;
}

.formular {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #707070;
  background: white;
  background: linear-gradient(to right, #C5003E, rgb(85, 15, 80));
  padding: 4rem 2rem;
  position: relative;
}
.formular:before {
  content: "";
  width: 40px;
  height: 40px;
  background: #fff;
  position: absolute;
  top: -25px;
  left: 50%;
  border-radius: 4px;
  transform: translateX(-50%) rotate(45deg);
}
.formular #form--container {
  position: relative;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 4rem;
  padding-bottom: 0;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;
  border: none !important;
  background: #fff;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  min-height: 46rem;
  align-items: center;
}
.formular #form--container form {
  flex: 1 0 40%;
}
.formular #form--container .form--header-content {
  flex: 1 0 40%;
  padding: 0 2rem;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: #fff;
}
@media (min-width: 760px) {
  .formular #form--container .form--header-content {
    padding: 2rem 4rem 2rem 2rem;
  }
}
.formular #form--container .stoerer {
  margin-top: 1rem;
}
.formular #form--container .stoerer * {
  color: #C5003E;
}
.formular #form--container .divider--arrow {
  width: 40px;
  margin-right: 4rem;
  margin-left: 0;
  align-self: stretch;
  overflow: hidden;
  position: relative;
}
.formular #form--container .divider--arrow:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.formular #form--container .divider--arrow img {
  position: absolute;
  top: 50%;
  left: 0;
  animation: wiggle 2s forwards infinite ease;
}
.formular #form--container h2 {
  margin-bottom: 1rem;
  font-size: 3.4rem;
  color: #C5003E;
}
.formular #form--container h4 {
  font-size: 2rem;
}
.formular #form--container h4 span {
  font-size: inherit;
}
.formular #form--container .formContent {
  padding: 0;
  margin-top: 2rem;
  max-width: 100% !important;
  text-align: left;
  flex: 1 0 40%;
}
.formular #form--container .formContent .question .next {
  font-size: 1.6rem;
}
.formular #form--container .formContent .question .inputSection {
  display: block;
  flex-wrap: wrap;
  text-align: left;
  margin: 2rem 0 4rem;
  align-items: center;
}
.formular #form--container .formContent .question .inputSection strong {
  font-size: 2rem;
}
.formular #form--container .formContent .question .inputSection .btn-outline {
  border: none !important;
  width: auto;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  color: black;
}
.formular #form--container .formContent .question .inputSection .btn-outline:hover {
  background: transparent;
  color: black;
}
.formular #form--container .formContent .question .inputSection .inputGroup {
  flex: 1 0 40%;
  min-width: 300px;
  margin-top: 2rem;
  text-align: left;
}
.formular #form--container .formContent .question .inputSection .inputGroup input[type=radio], .formular #form--container .formContent .question .inputSection .inputGroup input[type=checkbox] {
  display: none;
}
.formular #form--container .formContent .question .inputSection .inputGroup input[type=radio] ~ label, .formular #form--container .formContent .question .inputSection .inputGroup input[type=checkbox] ~ label {
  padding-left: 2.4rem;
  font-size: 1.6rem;
  position: relative;
  height: auto;
  user-select: none;
}
.formular #form--container .formContent .question .inputSection .inputGroup input[type=radio] ~ label:before, .formular #form--container .formContent .question .inputSection .inputGroup input[type=checkbox] ~ label:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: -1px;
  border: 1px solid #3d3d3d;
}
.formular #form--container .formContent .question .inputSection .inputGroup input[type=radio]:checked ~ label, .formular #form--container .formContent .question .inputSection .inputGroup input[type=checkbox]:checked ~ label {
  background: transparent;
}
.formular #form--container .formContent .question .inputSection .inputGroup input[type=radio]:checked ~ label:before, .formular #form--container .formContent .question .inputSection .inputGroup input[type=checkbox]:checked ~ label:before {
  background: #C5003E;
  border-color: #C5003E;
}
.formular #form--container .formContent .question .inputSection .inputGroup input[type=radio]:checked ~ label:after, .formular #form--container .formContent .question .inputSection .inputGroup input[type=checkbox]:checked ~ label:after {
  content: "";
  width: 8px;
  height: 3px;
  border: 2px solid white;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  left: 3px;
  top: 2px;
  transform: rotate(-45deg);
}
.formular #form--container .formContent .question .inputSection .inputGroup input, .formular #form--container .formContent .question .inputSection .inputGroup label {
  margin: 0;
}
.formular #form--container .formContent .question .inputSection .inputGroup.success {
  position: relative;
}
.formular #form--container .formContent .question .inputSection .inputGroup.success input {
  border-color: #71ae73;
  color: #71ae73;
  display: block;
}
.formular #form--container .formContent .question .inputSection .inputGroup.success:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: calc(100% - 4px);
  border-radius: 0 3rem 3rem 0;
  background: url(../img/icn-checkmark-success.svg) center no-repeat;
  background-size: 2rem;
  background-color: #fff;
  border: 2px solid #71ae73;
  border-left: none;
}
.formular #form--container .formContent .question .inputSection strong, .formular #form--container .formContent .question .inputSection p {
  display: block;
  width: 100%;
}
@media (max-width: 960px) {
  .formular #form--container {
    display: block;
  }
  .formular #form--container .form--header-content {
    border-right: none;
    margin-right: 0;
  }
}
@media (max-width: 760px) {
  .formular #form--container {
    display: block;
    padding: 2rem;
  }
  .formular #form--container .form--header-content {
    border-right: none;
    margin-right: 0;
    padding-right: 2rem;
  }
  .formular #form--container .form--header-content h2 {
    font-size: 1.6rem;
  }
  .formular #form--container .formContent {
    margin-top: 2rem;
  }
  .formular #form--container .formContent .question strong {
    font-size: 2rem !important;
  }
  .formular #form--container .formContent .question p {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .formular #form--container .formContent .question .inputSection .inputGroup {
    min-width: 100%;
    margin-top: 1rem;
  }
  .formular #form--container .formContent .question .inputSection .inputGroup label {
    font-size: 1.6rem !important;
    margin-bottom: 1rem !important;
  }
}
.formular #form--container.testformular {
  transition: all 300ms ease;
  background: #707070;
  margin: 0 !important;
  left: 0;
  right: 0;
  height: 100%;
}
.formular #form--container.testformular:before {
  content: none;
}
.formular #form--container.testformular #form {
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 760px) {
  .formular #form--container {
    padding: 2rem 1rem;
  }
  .formular #form--container .form--header-content h2 {
    margin-bottom: 0;
    font-size: 2rem !important;
  }
  .formular #form--container .form--header-content .stoerer {
    margin-top: 1rem;
  }
  .formular #form--container .form--header-content .stoerer h4 {
    font-size: 1.6rem;
  }
  .formular #form--container .formContent {
    margin-top: 0 !important;
  }
  .formular #form--container .formContent .question .inputSection strong {
    font-size: 2rem !important;
  }
  .formular #form--container .formContent .question .inputSection * {
    font-size: 1.6rem !important;
  }
  .formular #form--container .formContent .question .inputSection input[type=text], .formular #form--container .formContent .question .inputSection input[type=number], .formular #form--container .formContent .question .inputSection input[type=email] {
    font-size: 16px !important;
  }
}
.formular #form--container .btn-outline-input {
  margin-bottom: 0;
  outline: 0;
  border: 2px solid #dedede;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 3rem;
  font-size: 1.8rem;
  text-align: center;
}
.formular .text--success, .formular .alert {
  font-size: 1.6rem;
}
.formular .final--section {
  display: block !important;
}
.formular #timeout--notice {
  font-size: 1.6rem;
  margin-top: 1rem;
}
.formular .toggle_check {
  display: flex;
  align-items: center;
  padding-left: 0 !important;
}
.formular .toggle_check:before {
  content: none !important;
}
.formular .toggle_check .toggle {
  margin-right: 1rem;
}
.formular input:checked ~ .toggle_check .toggle:after {
  left: 2.2rem;
  background: #C5003E;
}

#mini #testplatz-sichern {
  min-height: 80vh;
  background: #DEDEDE;
}
#mini #testplatz-sichern:before {
  content: none;
}

#wirSuchenSie {
  padding: 60px 20px;
  background: linear-gradient(to right, #C5003E, rgb(85, 15, 80));
  background: #DEDEDE;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
@media (max-width: 760px) {
  #wirSuchenSie {
    padding: 1rem;
  }
}
#wirSuchenSie .akkordeonContent {
  max-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
}
#wirSuchenSie .akkordeonContent .akkordeonContainer {
  display: flex;
  flex-wrap: wrap;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft {
  padding: 40px 60px;
  flex: 1 0 0;
  position: relative;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft:after {
  content: "";
  height: 80%;
  width: 2px;
  background: #DEDEDE;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks {
  width: 100%;
  font-weight: 600;
  margin: 1rem 0;
  cursor: pointer !important;
  text-align: left;
  border: none;
  position: relative;
  border: none;
  background: none;
  display: block;
  user-select: none;
  text-decoration: none;
  font-size: 1.6rem;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks:after {
  content: url("../img/angle_right_p.svg");
  position: absolute;
  transform-origin: center;
  transform: scale(0.9);
  right: 0;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks:before {
  content: "";
  width: 0px;
  height: 3px;
  background: #C5003E;
  position: absolute;
  left: -60px;
  top: 7px;
  transition: 0.3s;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks:hover, #wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks.active {
  color: #C5003E;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks:hover:before, #wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks.active:before {
  width: 30px;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks:focus {
  color: #C5003E;
  outline: none;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonLeft .tablinks:focus:before {
  width: 30px;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonMid {
  padding: 40px 30px;
  position: relative;
  flex: 2 0 0;
}
@media (max-width: 760px) {
  #wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonMid {
    padding: 2rem;
  }
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonMid h3 {
  font-size: 3rem !important;
  margin-bottom: 1rem;
  font-family: univers;
  font-weight: 700;
  line-height: 1.2;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonMid .main__button {
  margin-top: 2rem;
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonMid .tabcontent {
  display: none;
  animation: fadeEffect 1s;
}
@keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#wirSuchenSie .akkordeonContent .akkordeonContainer .akkordeonRight {
  padding: 40px 60px;
  flex: 1 0 0;
  background: url("../img/akkordeonPicture1.jpg") no-repeat center;
  background-size: cover;
}
@media (max-width: 960px) {
  #wirSuchenSie .akkordeonRight {
    min-width: 100%;
    min-height: 300px;
  }
}
@media (max-width: 700px) {
  #wirSuchenSie .akkordeonLeft {
    min-width: 100%;
  }
  #wirSuchenSie .akkordeonLeft:after {
    display: none;
  }
  #wirSuchenSie .akkordeonMid:after {
    content: "";
    height: 2px;
    width: 90%;
    background: #DEDEDE;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes wiggle {
  0% {
    transform: translate(0, -50%);
    top: 50%;
  }
  50% {
    transform: translate(-10px, -50%);
    top: 50%;
  }
  100% {
    transform: translate(0, -50%);
    top: 50%;
  }
}
#version--redaktionell {
  background: white;
}
#version--redaktionell *:not(.sansserif) {
  font-family: "Roboto Slab", serif;
}
#version--redaktionell h1, #version--redaktionell h2 {
  font-size: 2.2rem;
  color: #1A1A1A;
}
@media (min-width: 840px) {
  #version--redaktionell h1, #version--redaktionell h2 {
    font-size: 4rem;
  }
}
#version--redaktionell header {
  background: white;
  width: 100%;
  border-bottom: 1px solid #C5003E;
}
#version--redaktionell header nav {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
}
#version--redaktionell header .header--hinweis {
  background: #C5003E;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
}
#version--redaktionell header .header--hinweis * {
  color: white;
}
#version--redaktionell header .header--hinweis a {
  text-decoration: none;
}
#version--redaktionell .main--section {
  width: 100%;
  max-width: 80rem;
  padding: 2rem;
  margin: 0 auto;
}
#version--redaktionell .main--section small {
  font-size: 1.2rem;
  font-family: univers, sans-serif;
}
#version--redaktionell .main--section hr {
  border: none;
  width: 10rem;
  background: #707070;
  height: 1px;
  margin: 1rem 0;
}
#version--redaktionell .main--section .testimonial {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}
#version--redaktionell .main--section .testimonial img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}
#version--redaktionell .main--section .testimonial .testimonial--info strong, #version--redaktionell .main--section .testimonial .testimonial--info p {
  font-size: 1.2rem;
  color: #707070;
  margin: 0;
}
#version--redaktionell .main--section .kk-bild {
  background-size: cover;
  width: 100%;
  height: 50vw;
  max-height: 40rem;
  cursor: pointer;
  margin: 2rem 0;
  background-position: center;
}
#version--redaktionell .main--section .krankenkassenbild {
  background-image: url("../img/krankenkasse/header.jpg");
}
#version--redaktionell .main--section .hoerenbild {
  background-image: url("../img/krankenkasse/hoeren.jpg");
  margin-top: 2rem;
}
#version--redaktionell .main--section .hoergeraetebild {
  background-image: url("../img/krankenkasse/hoergeraet.jpg");
  background-position: top center;
}
#version--redaktionell .krankenkassen {
  margin: 2rem 0;
}
@media (min-width: 540px) {
  #version--redaktionell .krankenkassen {
    display: flex;
    flex-wrap: wrap;
  }
}
#version--redaktionell .krankenkassen .radio--group {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  flex: 1 0 45%;
}
#version--redaktionell .krankenkassen .radio--group label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  border: 1px solid #707070;
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  color: #C5003E;
  transition: all 300ms ease;
  height: 5rem;
  justify-content: center;
  text-align: center;
}
#version--redaktionell .krankenkassen .radio--group label .kk--icon {
  width: 6rem;
  height: 4rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 1rem;
}
#version--redaktionell .krankenkassen .radio--group label.label-noicon {
  text-align: center;
  justify-content: center;
  height: 5rem;
  background: #C5003E;
  color: white;
  border-color: #C5003E;
}
#version--redaktionell .krankenkassen .radio--group label:hover {
  background: #DEDEDE;
  color: #C5003E;
}
#version--redaktionell .krankenkassen .radio--group input {
  display: none;
}
#version--redaktionell .krankenkassen .radio--group input:checked ~ label {
  background: white !important;
  color: #C5003E !important;
  border-color: #C5003E !important;
}
#version--redaktionell .krankenkassen .radio--group:nth-child(odd) {
  margin-right: 1rem;
}
#version--redaktionell .infobox {
  border: 1px solid #707070;
}
#version--redaktionell .infobox .top--content {
  padding: 2rem;
  background: #DEDEDE;
}
#version--redaktionell .infobox .top--content .checklist li {
  font-weight: normal;
}
#version--redaktionell .infobox .bottom--content {
  background: #C5003E;
  padding: 2rem;
}
#version--redaktionell .infobox .bottom--content * {
  color: white;
}
#version--redaktionell .infobox .bottom--content .main__button {
  border: 1px solid white;
  margin-top: 1rem;
}
#version--redaktionell #lightbox .lightbox {
  padding: 1rem;
  max-width: 100%;
}
#version--redaktionell #loader {
  background: white !important;
}
#version--redaktionell #loader h3 {
  text-align: center;
}
#version--redaktionell footer {
  text-align: center;
  padding: 2rem 0;
  border-top: 1px solid #C5003E;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
#version--redaktionell footer .legal--links {
  text-align: center;
  margin: 0 auto;
  display: block;
}
#version--redaktionell footer .legal--links a {
  display: block;
  margin-bottom: 1rem;
}
@media (min-width: 540px) {
  #version--redaktionell footer .legal--links {
    display: flex;
  }
}

#loginpage {
  display: flex;
  align-items: center;
  justify-content: center;
}
#loginpage .main__content {
  padding: 2rem;
}
#loginpage .main__content hr {
  margin: 4rem 0;
  border: none;
  width: 100%;
  height: 1px;
  background: #efefef;
}
#loginpage .main__content .message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  color: white;
  margin-bottom: 2rem;
  transition: all 300ms ease;
}
#loginpage .main__content .message.success {
  background: rgba(113, 174, 115, 0.3);
  border: 1px solid #71AE73;
  color: #71AE73;
}
#loginpage .main__content .message.error {
  background: rgba(197, 0, 62, 0.2);
  border: 1px solid #C5003E;
  color: #C5003E;
}
#loginpage .main__content .fadeout {
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
}

#kundenumfragepage .container {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
}
#kundenumfragepage h1 {
  text-align: center;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
}
#kundenumfragepage .chart--container {
  text-align: center;
  width: 100%;
  max-width: 60rem;
  margin: 3rem auto;
}
#kundenumfragepage .chart--container h2 {
  font-size: 3rem;
  color: black;
}
#kundenumfragepage .chart--container .chart--row {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
#kundenumfragepage .chart--container .chart--row .chart--content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 4rem;
  position: relative;
  height: 30rem;
}
#kundenumfragepage .chart--container .chart--row .chart--content .chart--bar {
  width: 8rem;
  background: #C5003E;
  height: 0;
  transition: 500ms ease all;
}
#kundenumfragepage .chart--container .chart--row .chart--line {
  width: 100%;
  height: 1px;
  background: #DEDEDE;
  position: absolute;
  left: 0;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(0) {
  bottom: 0%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(1) {
  bottom: 10%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(2) {
  bottom: 20%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(3) {
  bottom: 30%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(4) {
  bottom: 40%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(5) {
  bottom: 50%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(6) {
  bottom: 60%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(7) {
  bottom: 70%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(8) {
  bottom: 80%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(9) {
  bottom: 90%;
}
#kundenumfragepage .chart--container .chart--row .chart--line:nth-child(10) {
  bottom: 100%;
}
#kundenumfragepage .chart--container .legende {
  display: flex;
  justify-content: center;
}
#kundenumfragepage .chart--container .legende p {
  margin: 2rem 4rem;
  text-align: center;
  width: 8rem;
}
#kundenumfragepage .soft--row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
#kundenumfragepage .ohr {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C5003E !important;
  padding: 1rem;
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  border-radius: 50%;
  margin-right: 20px;
}
#kundenumfragepage .ohr img {
  width: 100% !important;
  max-width: 100% !important;
}
#kundenumfragepage #warum {
  margin-bottom: 4rem;
}
#kundenumfragepage #warum .content {
  width: 100%;
  max-width: 64rem;
}
#kundenumfragepage #warum h2 {
  margin-bottom: 0;
}

#hoergeraete-testen > img {
  position: absolute;
  display: none;
}
#hoergeraete-testen * {
  font-family: "value", sans-serif !important;
}
#hoergeraete-testen .main__content {
  max-width: 100%;
}
#hoergeraete-testen nav {
  padding: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  background: transparent;
}
@media (min-width: 540px) {
  #hoergeraete-testen nav {
    padding: 0;
  }
}
#hoergeraete-testen nav > img {
  width: 15rem;
  max-width: 30vw;
}
#hoergeraete-testen nav a {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: "value", sans-serif;
  font-size: 1.4rem;
}
#hoergeraete-testen nav a img {
  width: 2.5rem;
  margin-right: 0.5rem;
}
@media (min-width: 840px) {
  #hoergeraete-testen nav a {
    display: none;
  }
}
#hoergeraete-testen #header {
  position: relative;
  z-index: 0;
  max-width: 100%;
  display: block;
}
#hoergeraete-testen #header .header__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80vw;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__image {
    height: 100%;
  }
}
#hoergeraete-testen #header .header__inner {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  display: grid;
  padding: 2rem;
  grid-template-areas: "nav nav" "content content" "form form";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-gap: 2rem;
  padding-bottom: 4rem;
  align-items: flex-end;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__inner {
    grid-template-areas: "nav nav" "content form";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
  }
}
#hoergeraete-testen #header .header__inner nav {
  grid-area: nav;
}
#hoergeraete-testen #header .header__inner .header__content {
  position: relative;
  grid-area: content;
  min-height: 40vw;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__inner .header__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
#hoergeraete-testen #header .header__inner .header__content * {
  color: white;
}
#hoergeraete-testen #header .header__inner .header__content .phone__text {
  padding-left: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 3rem;
}
#hoergeraete-testen #header .header__inner .header__content .phone__text img {
  margin-right: 1rem;
}
#hoergeraete-testen #header .header__inner .header__content a {
  text-decoration: none;
}
@media (max-width: 839px) {
  #hoergeraete-testen #header .header__inner .header__content *:not(h1) {
    display: none !important;
  }
}
#hoergeraete-testen #header .header__inner .header__content h1 {
  text-align: center;
  font-size: 2.2rem;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__inner .header__content h1 {
    text-align: left;
  }
}
#hoergeraete-testen #header .header__inner .header__content .phone__link {
  font-size: 2.4rem;
  padding-left: 4rem;
}
#hoergeraete-testen #header .header__inner .header__content p {
  padding-left: 4rem;
}
#hoergeraete-testen #header .header__inner .header__content .more {
  display: flex;
  align-items: center;
  margin-top: 6rem;
}
#hoergeraete-testen #header .header__inner .header__content .more img {
  width: 3rem;
  margin-left: 1rem;
}
#hoergeraete-testen #header .header__inner .header__form {
  grid-area: form;
}
#hoergeraete-testen #header .header--image-hoergeraete-testen {
  background-image: url("../img/header_branded.jpg") !important;
  background-position: center bottom 30% !important;
  background-size: cover;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header--image-hoergeraete-testen {
    background-position: right 20% bottom 20% !important;
  }
}
@media (min-width: 200rem) {
  #hoergeraete-testen #header .header--image-hoergeraete-testen {
    background-position: right bottom 20% !important;
  }
}
#hoergeraete-testen #header .header__form #form {
  border-radius: 0.5rem;
  min-height: 54.5rem;
  overflow: visible;
  height: auto;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__form #form {
    width: 50rem;
    max-width: 50vw;
  }
}
#hoergeraete-testen #header .header__form #form .form__header {
  position: relative;
  text-align: left;
  transform: translate(-2rem, 2rem);
  width: calc(100% + 4rem);
  padding: 1rem 2rem 1rem 2rem;
  margin-bottom: 4rem;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__form #form .form__header {
    width: auto;
    display: inline-block;
    padding: 1rem 6rem 1rem 2rem;
  }
}
#hoergeraete-testen #header .header__form #form .form__header:before {
  content: none;
}
#hoergeraete-testen #header .header__form #form .form__header:after {
  all: unset;
  content: "";
  border: none;
  position: absolute;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__form #form .form__header:after {
    width: 5rem;
    height: 5rem;
    background: white;
    right: auto;
    left: 100%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
#hoergeraete-testen #header .header__form #form .form__content {
  height: auto;
}
#hoergeraete-testen #header .header__form #form input {
  font-weight: bold;
}
#hoergeraete-testen #header .header__form #form .radioGroup {
  margin-bottom: 2rem;
}
#hoergeraete-testen #header .header__form #form .radioGroup:last-child {
  margin-bottom: 0;
}
#hoergeraete-testen #header .header__form #form .radioGroup label.btn-outline {
  color: #707070 !important;
  font-weight: bold;
  font-size: 1.4rem;
}
@media (min-width: 840px) {
  #hoergeraete-testen #header .header__form #form .radioGroup label.btn-outline {
    font-size: 1.6rem;
  }
}
#hoergeraete-testen #header .header__form #form .radioGroup input:checked ~ label.btn-outline {
  color: #C5003E !important;
}
#hoergeraete-testen #header .header__form #form .progress__container {
  display: flex;
  align-items: center;
  margin-top: 0;
  padding: 2rem;
}
#hoergeraete-testen #header .header__form #form .progress__container #progress {
  height: 1rem;
  border-radius: 1rem;
  margin: 0;
  border: none;
  background: #A7A5A5;
  width: 100%;
}
#hoergeraete-testen #header .header__form #form .progress__container #progress div {
  height: 100%;
  background: #C5003E;
  border-radius: 1rem;
}
#hoergeraete-testen #header .header__form #form .progress__container p {
  margin-left: 1rem;
  font-size: 1.2rem;
  width: 12rem;
  text-align: right;
}
#hoergeraete-testen #benefits {
  padding: 4rem 2rem;
}
#hoergeraete-testen #benefits .boxed__content {
  text-align: center;
}
@media (min-width: 540px) {
  #hoergeraete-testen #benefits .boxed__content {
    display: flex;
    justify-content: space-around;
  }
}
#hoergeraete-testen #benefits .boxed__content .benefit {
  text-align: center;
  margin: 0 auto 2rem;
}
#hoergeraete-testen #benefits .boxed__content .benefit p {
  font-weight: bold;
  max-width: 20ch;
  font-size: 1.6rem;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  #hoergeraete-testen #benefits .boxed__content .benefit p {
    font-size: 2.4rem;
  }
}
#hoergeraete-testen #benefits .boxed__content .benefit img {
  width: 5rem;
}
@media (min-width: 1200px) {
  #hoergeraete-testen #benefits .boxed__content .benefit img {
    width: 7rem;
  }
}
#hoergeraete-testen .boxed__content {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
}
#hoergeraete-testen #zufriedenheit h3 {
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.2;
}
#hoergeraete-testen #zufriedenheit .google {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 2rem auto 0;
}
@media (min-width: 540px) {
  #hoergeraete-testen #zufriedenheit .google {
    text-align: left;
    display: flex;
  }
}
#hoergeraete-testen #zufriedenheit .google img {
  width: 13rem;
  margin-top: 2rem;
}
@media (min-width: 540px) {
  #hoergeraete-testen #zufriedenheit .google img {
    margin-left: 4rem;
  }
}
#hoergeraete-testen #zufriedenheit .hoergeraete-testen--berwertungen-container {
  padding: 0 2rem;
}
#hoergeraete-testen #zufriedenheit #hoergeraete-testen-bewertungen {
  margin-top: 2rem;
  position: relative;
}
#hoergeraete-testen #zufriedenheit #hoergeraete-testen-bewertungen .prevArrow {
  position: absolute;
  left: -2rem;
  top: 50%;
  transform: translateY(-50%);
}
#hoergeraete-testen #zufriedenheit #hoergeraete-testen-bewertungen .nextArrow {
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
}
#hoergeraete-testen #zufriedenheit #hoergeraete-testen-bewertungen .bewertung {
  padding: 2rem;
  margin: 1rem;
  border: 1px solid #DEDEDE;
}
#hoergeraete-testen #zufriedenheit #hoergeraete-testen-bewertungen .bewertung .bewertung__sterne {
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem;
}
#hoergeraete-testen #zufriedenheit #hoergeraete-testen-bewertungen .bewertung p {
  font-size: 1.6rem;
}
#hoergeraete-testen #testhoerer-gesucht h3 {
  font-size: 2.4rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}
@media (min-width: 540px) {
  #hoergeraete-testen #testhoerer-gesucht .soft--row {
    display: flex;
    flex-wrap: wrap;
  }
}
#hoergeraete-testen #testhoerer-gesucht .soft--row .col--1 {
  flex: 1 0 33%;
}
#hoergeraete-testen #testhoerer-gesucht .soft--row .col--2 {
  flex: 1 0 66%;
}
#hoergeraete-testen #testhoerer-gesucht .soft--row .col--2 ul {
  margin-bottom: 2rem;
}
#hoergeraete-testen #testhoerer-gesucht .soft--row .col--2 .main__button {
  display: inline-block;
  width: auto;
  padding: 1rem 4rem;
}
#hoergeraete-testen .content {
  max-width: 140rem;
}

.branded__footer {
  background: #444444;
  padding: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 840px) {
  .branded__footer {
    justify-content: space-between;
    flex-direction: row;
  }
}
.branded__footer .legal--links {
  display: block;
}
.branded__footer .legal--links a {
  display: block;
  margin-top: 2rem;
  font-size: 1.6rem;
}
@media (min-width: 840px) {
  .branded__footer .legal--links {
    display: flex;
  }
  .branded__footer .legal--links a {
    display: block;
    margin-top: 0;
  }
}
.branded__footer * {
  color: white;
  font-family: "value", sans-serif;
  font-weight: bold;
}
.branded__footer img {
  width: 15rem;
  max-width: 50vw;
  display: block;
}

#nulltarif-hoergeraete * {
  font-family: "Noto sans", sans-serif;
}
#nulltarif-hoergeraete .main__content {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-areas: "image" "content" "benefits" "footer";
  grid-template-columns: 1fr;
  background: #E8E8E8;
  grid-template-rows: auto auto;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content {
    background: white;
    grid-template-areas: "image content" "benefits benefits" "footer footer";
    grid-template-columns: 45% 55%;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content {
    grid-template-columns: 40% 60%;
    grid-template-areas: "image content" "title benefits" "footer footer";
  }
}
#nulltarif-hoergeraete .main__content footer {
  grid-area: footer;
  padding: 4rem 2rem;
}
#nulltarif-hoergeraete .main__content footer .footer--content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-rows: auto;
  grid-gap: 2rem;
  grid-template-areas: "phone" "links" "copyright" "legal";
  grid-template-columns: 1fr;
}
@media (min-width: 1200px) {
  #nulltarif-hoergeraete .main__content footer .footer--content {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "copyright phone links" "legal legal legal";
  }
}
#nulltarif-hoergeraete .main__content footer .footer--content * {
  margin: 0;
}
#nulltarif-hoergeraete .main__content footer .footer--content .phone {
  grid-area: phone;
  font-size: 2rem;
}
@media (min-width: 1200px) {
  #nulltarif-hoergeraete .main__content footer .footer--content .phone {
    font-size: 3.6rem;
  }
}
#nulltarif-hoergeraete .main__content footer .footer--content .phone img {
  margin-right: 1.5rem;
  width: 2rem !important;
  height: 2rem !important;
}
@media (min-width: 1200px) {
  #nulltarif-hoergeraete .main__content footer .footer--content .phone img {
    width: 3.6rem !important;
    height: 3.6rem !important;
  }
}
#nulltarif-hoergeraete .main__content footer .footer--content .copyright {
  grid-area: copyright;
}
@media (min-width: 1200px) {
  #nulltarif-hoergeraete .main__content footer .footer--content .copyright {
    text-align: left;
  }
}
#nulltarif-hoergeraete .main__content footer .footer--content .legal--links {
  grid-area: links;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 540px) {
  #nulltarif-hoergeraete .main__content footer .footer--content .legal--links {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media (min-width: 1200px) {
  #nulltarif-hoergeraete .main__content footer .footer--content .legal--links {
    text-align: right;
    justify-content: flex-end;
  }
}
#nulltarif-hoergeraete .main__content footer .footer--content .legal--links a {
  margin: 0 0 2rem 0;
  color: black;
}
@media (min-width: 540px) {
  #nulltarif-hoergeraete .main__content footer .footer--content .legal--links a {
    margin: 0 1rem;
  }
}
#nulltarif-hoergeraete .main__content footer .legal {
  grid-area: legal;
  color: #C9C9C9 !important;
  margin-top: 2rem;
}
#nulltarif-hoergeraete .main__content .boxed--content {
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
}
#nulltarif-hoergeraete .main__content .header--section {
  grid-area: image;
  background: linear-gradient(342.22deg, #444444 28.56%, #131313 109.37%);
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 540px) {
  #nulltarif-hoergeraete .main__content .header--section {
    padding: 4rem 4rem 0;
  }
}
#nulltarif-hoergeraete .main__content .header--section .header--logo {
  width: 12rem;
  height: auto;
  margin-bottom: 2vw;
}
@media (min-width: 540px) {
  #nulltarif-hoergeraete .main__content .header--section .header--logo {
    width: 16rem;
  }
}
#nulltarif-hoergeraete .main__content .header--section .header--image {
  width: 70%;
  max-width: 30rem;
  margin: 0 auto;
  display: block;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--section .header--image {
    width: 90%;
    max-width: 40rem;
  }
}
#nulltarif-hoergeraete .main__content .text--area {
  grid-area: image;
  padding: 6rem 2rem 0 2rem;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .text--area {
    padding: 10rem 2rem 0 4rem;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .text--area {
    text-align: left;
    grid-area: title;
    padding: 2rem 2rem;
    background: #444444;
    display: flex;
    align-items: center;
  }
}
#nulltarif-hoergeraete .main__content .text--area h2 {
  color: white;
  font-size: 2rem;
  margin: 0;
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .text--area h2 {
    font-size: 3.6rem;
  }
}
#nulltarif-hoergeraete .main__content .benefits {
  grid-area: benefits;
  background: #E8E8E8;
  border: none !important;
  padding: 2rem;
}
@media (min-width: 840px) {
  #nulltarif-hoergeraete .main__content .benefits .benefits--list {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
#nulltarif-hoergeraete .main__content .benefits .benefits--list li {
  list-style-type: none;
  text-align: center;
  margin-left: 0;
  margin-bottom: 2rem;
}
@media (min-width: 840px) {
  #nulltarif-hoergeraete .main__content .benefits .benefits--list li {
    margin-bottom: 0;
    padding: 1rem 2rem;
  }
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .benefits .benefits--list li {
    padding: 1rem 4rem;
  }
}
@media (min-width: 840px) {
  #nulltarif-hoergeraete .main__content .benefits .benefits--list li:not(:last-child) {
    border-right: 1px solid white;
  }
}
#nulltarif-hoergeraete .main__content .benefits .benefits--list li img {
  width: 4rem;
  margin-bottom: 1rem;
}
@media (min-width: 840px) {
  #nulltarif-hoergeraete .main__content .benefits .benefits--list li img {
    width: 3rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .benefits .benefits--list li img {
    width: 5rem;
  }
}
#nulltarif-hoergeraete .main__content .benefits .benefits--list li p {
  font-size: 1.6rem;
}
#nulltarif-hoergeraete .main__content .header--content {
  grid-area: content;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 1rem;
  width: calc(100% - 2rem);
  margin: -16rem auto 0;
}
@media (min-width: 360px) {
  #nulltarif-hoergeraete .main__content .header--content {
    margin: -20rem auto 0;
    width: calc(100% - 4rem);
  }
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--content {
    margin: 0;
    border-radius: 0;
    width: 100%;
  }
}
#nulltarif-hoergeraete .main__content .header--content form {
  width: 100%;
  max-width: 70rem;
}
#nulltarif-hoergeraete .main__content .header--content form .main__button, #nulltarif-hoergeraete .main__content .header--content form .btn-outline, #nulltarif-hoergeraete .main__content .header--content form .btn-outline-input {
  border-radius: 1rem;
  font-size: 1.6rem;
  padding: 1rem 2rem;
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .header--content form .main__button, #nulltarif-hoergeraete .main__content .header--content form .btn-outline, #nulltarif-hoergeraete .main__content .header--content form .btn-outline-input {
    padding: 2rem 4rem;
    font-size: 2rem;
  }
}
#nulltarif-hoergeraete .main__content .header--content form .inputGroup.success::before {
  border-radius: 0 1rem 1rem 0;
}
#nulltarif-hoergeraete .main__content .header--content form em {
  font-style: italic;
  font-size: 1.4rem;
  line-height: 1.2;
  display: block;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--content form em {
    font-size: 1.6rem;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .header--content form em {
    font-size: 2rem;
  }
}
#nulltarif-hoergeraete .main__content .header--content form #bullets {
  margin-top: 4rem;
  padding: 0 2rem;
  margin-bottom: 0;
}
#nulltarif-hoergeraete .main__content .header--content form .earlybird {
  margin: 0 auto;
  text-align: center;
}
#nulltarif-hoergeraete .main__content .header--content form .inputSection > strong {
  font-size: 3rem;
  text-align: left;
  line-height: 1.4;
}
#nulltarif-hoergeraete .main__content .header--content form .icon--list {
  margin: 2rem 0;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--content form .icon--list {
    margin: 4rem 0;
  }
}
#nulltarif-hoergeraete .main__content .header--content form .icon--list li {
  list-style-type: none;
  margin-bottom: 2rem;
  margin-left: 0;
  display: flex;
  align-items: center;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--content form .icon--list li {
    text-align: left;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .header--content form .icon--list li {
    margin-bottom: 4rem;
  }
}
#nulltarif-hoergeraete .main__content .header--content form .icon--list li img {
  margin-right: 2rem;
  width: 3rem;
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .header--content form .icon--list li img {
    width: 5rem;
  }
}
#nulltarif-hoergeraete .main__content .header--content form .icon--list li p {
  font-size: 1.4rem;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--content form .icon--list li p {
    font-size: 1.6rem;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .header--content form .icon--list li p {
    font-size: 2rem;
  }
}
#nulltarif-hoergeraete .main__content .header--content form h1 {
  color: black;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
@media (min-width: 640px) {
  #nulltarif-hoergeraete .main__content .header--content form h1 {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1000px) {
  #nulltarif-hoergeraete .main__content .header--content form h1 {
    font-size: 5rem;
  }
}
#nulltarif-hoergeraete .main__content .header--content form h1 span {
  color: #C5003E;
  font: inherit;
  text-decoration: underline;
}
#nulltarif-hoergeraete.danke--page h1 {
  display: flex;
  align-items: flex-start;
}
#nulltarif-hoergeraete.danke--page h1 span {
  background: #C5003E;
  padding: 1rem;
  border-radius: 50%;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  display: block;
  position: relative;
  margin-right: 1rem;
}
@media (min-width: 840px) {
  #nulltarif-hoergeraete.danke--page h1 span {
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
  }
}
#nulltarif-hoergeraete.danke--page h1 span img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
}
#nulltarif-hoergeraete.danke--page .danke--content-box .phone {
  margin-top: 3rem;
  font-size: 2.4rem;
  font-weight: bold;
}
#nulltarif-hoergeraete.danke--page .danke--content-box .phone img {
  margin-right: 1rem;
}