#hoergeraete
    background: linear-gradient(to top, #b7bbba, #c3c4c6 20%, #f0f0f0 25%)
    .content
        padding-bottom: 0
    h1
        color: $rot
        @media (min-width: $mobile)
            max-width: 400px
            width: 50%
    img
        display: block
        width: 60vw
        min-width: 25rem
        margin: 0 auto
        
        @media (min-width: $mobile)
            width: 100%
            margin: 0 3rem 0 auto
            margin-top: -12.5rem
            max-width: 50vw
        @media (min-width: $max-width)
            margin-top: -12.5rem
            max-width: 70rem
#kennenlernpaket
    background: #f0f0f0
    cursor: pointer

    @media (min-width: $mobile)
        //display: flex
    .content, .col--1
        @media (min-width: $mobile)
            flex: 1 0 45%
        // padding-bottom: 0
    h1
        color: $rot
    .kennenlernpaket
        display: grid
        grid-template-areas: "p1 p2" "p3 p4" "p5 p5"
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: auto auto auto
        border: 1rem solid $rot
        .paket
            position: relative
        .plus
            display: flex
            align-items: center
            justify-content: center
            width: 5rem
            height: 5rem
            line-height: 1
            border-radius: 50%
            background: $rot
            border: 3px solid white
            box-shadow: 0 0 1rem rgba(black, 0.25)
            font-size: 5rem
            color: white
            font-weight: bold
            font-family: $value
            position: absolute
            top: 50%
            right: 0
            transform: translate(50%, -50%)
            z-index: 2
        .paket-1
            .plus
                top: 100%
                @media (min-width: $mobile-s)
                    top: 50%
        .paket-2, .paket-3, .paket-4
            .plus
                display: none
                @media (min-width: $mobile-s)
                    display: flex
        .paket-5
            img
                width: 50%
                display: block
                margin: 0 auto 
            .plus
                left: 50%
                top: 0
                right: auto
                transform: translate(-50%,-50%)
        @media (min-width: $mobile-s)
            grid-template-areas: "p1 p2 p3 p4" ". p5 p5 ."
            grid-template-columns: repeat(4, 1fr)
            grid-template-rows: auto auto
            
            
        @media (min-width: $mobile)
            grid-template-areas: "p1 p2 p3 p4 p5"
            grid-template-columns: repeat(5, 1fr)
            grid-template-rows: auto
            .paket-5
                img
                    width: 100%
                .plus
                    top: 50%
                    left: 0
                    right: auto
                    transform: translate(-50%,-50%)

        .paket-1
            grid-area: p1
        .paket-2
            grid-area: p2
        .paket-3
            grid-area: p3
        .paket-4
            grid-area: p4
        .paket-5
            grid-area: p5
    &.kennenlernpaket--geschenk
        .kennenlernpaket
            display: grid
            grid-template-areas: "p1 p2 p3 p4" ". p5 p5 ."
            grid-template-columns: repeat(4, 1fr)
            grid-template-rows: auto auto
            @media (max-width: 539px)
                .plus
                    font-size: 2rem
                    width: 3rem
                    height: 3rem
            
            .paket-1
                .plus
                    top: 50%
                    
            .paket-2, .paket-3, .paket-4
                .plus
                    display: flex
            .paket-5
                img
                    width: 50%
                    display: block
                    margin: 0 auto 
                .plus
                    left: 50%
                    top: 0
                    right: auto
                    transform: translate(-50%,-50%)
            @media (min-width: $mobile)
                grid-template-areas: "p1 p2 p3 p4 p5"
                grid-template-columns: repeat(5, 1fr)
                grid-template-rows: auto
                .paket-5
                    img
                        width: 100%
                    .plus
                        top: 50%
                        left: 0
                        right: auto
                        transform: translate(-50%,-50%)


        
