.payback--page
    nav
        display: flex
        align-items: center
        justify-content: space-between
        .payback--logo, .amplifon--logo 
            width: 120px
            @media (min-width: $mobile)
                width: 200px
    #version--payback
        grid-template-columns: repeat(4, 1fr)
        grid-template-areas: 'b1 b2 b3 b4'
        img
            &:nth-child(2)
                display: block
