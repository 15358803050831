#hoergeraete-testen
	>img
		position: absolute
		display: none
	*
		font-family: $value!important
	.main__content
		max-width: 100%
	nav
		padding: 0rem
		@include mobile
			padding: 0
		display: flex
		justify-content: space-between
		align-items: center
		width: 100%
		z-index: 10
		background: transparent
		>img
			width: 15rem
			max-width: 30vw
		
		a
			color: white
			display: flex
			align-items: center
			text-decoration: none
			font-family: $value
			font-size: 1.4rem
			img
				width: 2.5rem
				margin-right: 0.5rem
			@include tablet
				display: none
	#header
		position: relative
		z-index: 0
		max-width: 100%
		display: block
		.header__image
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 80vw
			@include tablet
				height: 100%
		.header__inner
			width: 100%
			max-width: 140rem
			margin: 0 auto
			display: grid
			padding: 2rem
			grid-template-areas: 'nav nav' 'content content' 'form form'
			grid-template-columns: 1fr auto
			grid-template-rows: auto auto
			@include tablet
				grid-template-areas: 'nav nav' 'content form'
				grid-template-columns: 1fr auto
				grid-template-rows: auto auto
			grid-gap: 2rem
			padding-bottom: 4rem
			align-items: flex-end

			nav
				grid-area: nav
			

			.header__content
				position: relative
				grid-area: content
				min-height: 40vw
				@include tablet
					display: flex
					flex-direction: column
					justify-content: flex-end
				*
					color: white
				.phone__text
					padding-left: 0
					display: flex
					align-items: center
					font-weight: bold
					margin-top: 3rem
					img
						margin-right: 1rem
				a
					text-decoration: none
				*:not(h1)
					@include tablet-max
						display: none!important
				h1
					text-align: center
					font-size: 2.2rem
					@include tablet
						text-align: left
				.phone__link
					font-size: 2.4rem
					padding-left: 4rem
				p
					padding-left: 4rem
				.more
					display: flex
					align-items: center
					margin-top: 6rem
					img
						width: 3rem
						margin-left: 1rem

			.header__form
				grid-area: form
		.header--image-hoergeraete-testen
			background-image: url('../img/header_branded.jpg')!important
			background-position: center bottom 30% !important
			@include tablet
				background-position: right 20% bottom 20%!important
			background-size: cover
			@media (min-width: 200rem)
				background-position: right bottom 20%!important

		// FORMULAR ===================================================================
		.header__form
			#form
				border-radius: 0.5rem
				min-height: 54.5rem
				@include tablet
					width: 50rem
					max-width: 50vw
				overflow: visible
				height: auto
				.form__header
					position: relative
					text-align: left
					transform: translate(-2rem, 2rem)
					width: calc(100% + 4rem)
					padding: 1rem 2rem 1rem 2rem
					@include tablet
						width: auto
						display: inline-block
						padding: 1rem 6rem 1rem 2rem
					margin-bottom: 4rem
					&:before
						content: none
					&:after
						all: unset
						content: ""
						border: none
						position: absolute
						@include tablet
							width: 5rem
							height: 5rem
							background: white
							right: auto
							left: 100%
							top: 50%
							transform: translate(-50%, -50%) rotate(45deg)
				.form__content
					height: auto
				input
					font-weight: bold
				.radioGroup
					margin-bottom: 2rem
					&:last-child
						margin-bottom: 0
					label.btn-outline
						color: $grau!important
						font-weight: bold
						font-size: 1.4rem
						@include tablet
							font-size: 1.6rem
					input:checked
						&~label.btn-outline
							color: $rot!important
				.progress__container
					display: flex
					align-items: center
					margin-top: 0
					padding: 2rem
					#progress
						height: 1rem
						border-radius: 1rem
						margin: 0
						border: none
						background: #A7A5A5
						width: 100%
						div
							height: 100%
							background: $rot
							border-radius: 1rem
					p
						margin-left: 1rem
						font-size: 1.2rem
						width: 12rem
						text-align: right


	// FORMULAR ENDE ===================================================================

	#benefits
		padding: 4rem 2rem
		.boxed__content
			text-align: center
			@include mobile
				display: flex
				justify-content: space-around
			.benefit
				text-align: center
				margin: 0 auto 2rem
				p
					font-weight: bold
					max-width: 20ch
					font-size: 1.6rem
					margin: 0 auto
					@include desktop
						font-size: 2.4rem
				img
					width: 5rem
					@include desktop
						width: 7rem
	.boxed__content
		width: 100%
		max-width: 140rem
		margin: 0 auto	

	// Zufriedenheit =====================================

	#zufriedenheit
		h3
			font-size: 2.4rem
			text-align: center
			line-height: 1.2
		.google
			align-items: center
			justify-content: center
			text-align: center
			@include mobile
				text-align: left
				display: flex
			margin: 2rem auto 0
			img
				width: 13rem
				margin-top: 2rem
				@include mobile
					margin-left: 4rem
		.hoergeraete-testen--berwertungen-container
			padding: 0 2rem
		#hoergeraete-testen-bewertungen
			margin-top: 2rem
			position: relative
			.prevArrow
				position: absolute
				left: -2rem
				top: 50%
				transform: translateY(-50%)
			.nextArrow
				position: absolute
				right: -2rem
				top: 50%
				transform: translateY(-50%)
			.bewertung
				padding: 2rem
				margin: 1rem
				border: 1px solid $hellgrau
				.bewertung__sterne
					display: flex
					align-items: center
					margin: 1rem 0 2rem
				p
					font-size: 1.6rem
	#testhoerer-gesucht
		h3
			font-size: 2.4rem
			line-height: 1.2
			margin-bottom: 2rem
		.soft--row
			@include mobile
				display: flex
				flex-wrap: wrap
			.col--1
				flex: 1 0 33%
			.col--2
				flex: 1 0 66%
				ul
					margin-bottom: 2rem
				.main__button
					display: inline-block
					width: auto
					padding: 1rem 4rem
	.content
		max-width: 140rem
.branded__footer
	background: $dgrau
	padding: 2rem
	align-items: center
	display: flex
	flex-direction: column
	@include tablet
		justify-content: space-between
		flex-direction: row
	.legal--links
		display: block
		a
			display: block
			margin-top: 2rem
			font-size: 1.6rem
		@include tablet
			display: flex
			a
				display: block
				margin-top: 0
	*
		color: white
		font-family: $value
		font-weight: bold
	
	img
		width: 15rem
		max-width: 50vw
		display: block
