#mini
	background: white
	nav
		padding: 0
		border-bottom: 1px solid $hellgrau
		.nav--content
			margin: 0 auto
			width: 100%
			max-width: $max-width
			display: flex
			align-items: center
			justify-content: space-between
			padding: 1rem 2rem
			background: #fff
	#breadCrumb
		padding: 40px 0px 20px 0px
		a
			color: grey
			font-size: 1.4rem
			font-weight: 100!important
			text-decoration: underline
			&:hover
				color: $rot
		img
			height: 8px
			margin: 0px 5px
		@media (max-width: 700px)
			display: none
	.content--container
		width: 100%
		max-width: $max-width
		margin: 0 auto
		padding: 0 2rem

	.img-zoom-lens 
		position: absolute
		border: 1px solid #d4d4d4
		/*set the size of the lens:*/
		width: 150px
		height: 150px
	.image-zoom-result 
		border: 1px solid #d4d4d4
		width: 300px
		height: 300px
		position: absolute
	.zoom-box
		width: 100%
		height: 100%
		position: relative
		transition: none
		border-radius: 8px
		*
			transition: none


	#product
		display: flex
		margin-bottom: 60px
		flex-wrap: wrap
		.productGallery
			display: inline-flex
			flex: 2 0 0
			flex-wrap: wrap
			@media (max-width: 700px)
				max-width: 100%
				.sidePictures
					flex-direction: row
					order: 2
					margin-right: 0px !important
					display: flex
					flex-direction: row !important
					justify-content: space-between
					flex-wrap: wrap
					
				.bigPicture
					min-width: 100%
					order: 1
					margin-bottom: 30px
					max-height: 350px
			@media (max-width: 380px)
				.bigPicture
					max-height: 330px
			@media (max-width: 320px)
				.bigPicture
					max-height: 250px
			.sidePictures
				flex: 1 0 0
				flex-direction: column
				margin-right: 20px
				.smallPicture
					box-shadow: $shadow
					height: 102px
					margin-bottom: 20px
					cursor: pointer
					border-radius: 8px
					display: block
					img
						height: 100%
						border-radius: 8px
						display: block
						width: 100%
					&:hover
						opacity: 0.7
					&:last-child
						margin-bottom: 0px
			.bigPicture
				//background-image: url('../img/productImg2.jpg')
				background-position: center
				background-size: cover
				flex: 6 0 0
				border-radius: 8px
				box-shadow: $shadow
				margin-right: 40px
				height: 468px
				position: relative
				#zoomHinweis
					margin: 8px 0 0 8px
					font-size: 1.2rem
					color: grey
				#expandedImg
					display: block
					position: relative
					top: -10%
					@media (max-width: 1060px)
						top: 0
					@media (max-width: 960px)
						top: -25%
					@media (max-width: 800px)
						top: -15%
					@media (max-width: 600px)
						top: 0
				#topProduct
					z-index: 999
					position: absolute
					top: 50px
					right: 0px
					background: #232F3E
					padding: 5px 10px 2px
					&:before
						content: ""
						display: inline-block
						width: 0
						height: 0
						border-style: solid
						border-width: 0 12px 33px 0
						border-color: transparent #232F3E transparent transparent
						position: absolute
						left: -12px
						top: 0
					p
						strong
							color: white
					span
						color: $rot
						font-weight: bold
		.productDescription
			flex: 1 0 0
			display: flex
			flex-direction: column
			align-self: center
			@media (max-width: 960px)
				min-width: 100%
			h1
				margin-bottom: 1rem
			h3
				margin-bottom: 40px
			h4
				color: $rot
				font-weight: bold
				font-size: 1.6rem
				// margin-bottom: 15px
			.checklist
				li
					font-weight: 400
			@media (max-width: 960px)
				padding-top: 30px
				#topProduct
					left: -20px
					margin-top: 30px
					display: block
					
		@media (max-width: 960px)
			.productGallery
				margin-bottom: 40px
				.bigPicture
					margin-right: 0px






	#iframeCenter
		margin: 0 auto
		width: 1000px
		&:before
			content: ""
			position: absolute
			z-index: 1
			display: block
			right: 0
			height: 120px
			width: 220px
			background: white
		@media (max-width: 1000px)
			display: none


	.btn-large
		@media (max-width: 450px)
			font-size: 1.6rem

	.productGalleryMobile
		width: 100%
		overflow: hidden
		display: none
		position: relative
		margin-top: 2rem
		.mobile-slider-wrapper
			.slick-list.draggable
				border-radius: 7px
			.slides-mini
				width: 100vw
				overflow: hidden
				img
					height: auto
					width: 100%
		#topProduct
			z-index: 999
			position: absolute
			top: 50px
			right: 0px
			background: #232F3E
			padding: 5px 10px 2px
			p
				strong
					color: white
			@media (max-width: 760px)
				top: 20px
			&:before
				content: ""
				display: inline-block
				width: 0
				height: 0
				border-style: solid
				border-width: 0 12px 33px 0
				border-color: transparent #232F3E transparent transparent
				position: absolute
				left: -12px
				top: 0
			p
				strong
					color: white
			span
				color: $rot

		@media (max-width: 700px)
			display: block
	.productGallery
		display: inline-flex !important
		@media (max-width: 700px)
			display: none !important

	.i-right-angle
		position: absolute
		top: 50%
		right: 20px
		height: 30px
		cursor: pointer
	.i-left-angle
		position: absolute
		top: 50%
		left: 20px
		z-index: 9999
		height: 30px
		cursor: pointer
	
	#form--container
		position: relative
		// max-width: 420px
		border: 2px solid $rot
		z-index: 999
		background: #fff
		height: 625px
		@media (max-width: 500px)
			height: 670px
		.formHeader
			background: $gradient3
			padding: 10px 10px 50px
			text-align: center
			position: relative
			&:before
				content: ""
				position: absolute
				left: 0
				bottom: -1px
				width: 100%
				height: 25px
				background: url('../img/abdecker.svg') center no-repeat
				background-size: cover
				@media (min-width: 670px) and (max-width: 980px)
					height: 35px
				
			h2
				color: white
				font-size: 1.8rem
				line-height: 1.2
				margin-bottom: 15px
				span
					// font-family: $font-family-headline-light!important
					font-size: inherit
					color: white
					line-height: inherit
			h4
				color: white
				span
					color: inherit
		.formContent
			padding: 20px
			max-width: 380px
			margin: 0 auto
			background: #fff
			height: 70%
			@media (max-width: 500px)
				height: 68%

			.question
				
				display: none
				text-align: center
				&.active
					display: flex
					flex-direction: column
					justify-content: space-between
					height: 100%
				input[type="radio"]
					display: none
					&:checked~label
						// background: $rot
						// color: white
						// border: 2px solid $lila
					&~label
						cursor: pointer
				strong
					font-size: 2rem
					line-height: 1.3
				p
					font-size: 1.6rem

	#kundenzufriedenheit
		.soft--row
			.ohr
				display: flex
				align-items: center
				justify-content: center
				background: $rot!important
				padding: 1rem
	#dynamic__footer
		max-width: 100%
	footer
		padding-bottom: 100px
		@media(min-width: $mobile)
			padding-bottom: 150px
		.content
			max-width: 100%
		.content--container
			border-top: 1px solid $hellgrau
			display: flex
			justify-content: space-between
			align-items: center
			@media (max-width: 960px)
				display: block
#mini
	.logo_test
		margin: 2rem auto!important
#mini.testplatz--page
	footer
		padding-bottom: 0!important
.alert
	color: $rot
	padding: 5px
	text-align: center
	margin-top: 1rem


.formActive
	display: block

.formular
	// min-height: 100vh
	// display: flex
	// align-items: center
	width: 100vw
	left: 50%
	right: 50%
	margin-left: -50vw
	margin-right: -50vw
	background: $grau
	background: white
	background: $gradient4
	padding: 4rem 2rem
	position: relative
	&:before
		content: ""
		width: 40px
		height: 40px
		background: #fff
		position: absolute
		top: -25px
		left: 50%
		border-radius: 4px
		transform: translateX(-50%) rotate(45deg)

	#form--container
		position: relative
		box-shadow: $shadow
		padding: 4rem
		padding-bottom: 0
		max-width: 1200px
		margin: 0 auto
		border-radius: 8px
		border: none!important
		background: #fff
		height: auto!important
		display: flex
		flex-wrap: wrap
		min-height: 46rem
		align-items: center
		form
			flex: 1 0 40%
	
		.form--header-content
			flex: 1 0 40%
			padding: 0 2rem
			@media (min-width: 760px)
				padding: 2rem 4rem 2rem 2rem
			//border-right: 1px solid $grau
			align-self: stretch
			display: flex
			flex-direction: column
			justify-content: center
			position: relative
			background: #fff
		.stoerer
			margin-top: 1rem
			*
				color: $rot
		.divider--arrow
			width: 40px
			margin-right: 4rem
			margin-left: 0
			align-self: stretch
			overflow: hidden
			position: relative
			&:before
				content: ""
				width: 100%
				height: 100%
				position: absolute
				top: 0
				left: -100%
				border-radius: 50%
				box-shadow: 0 0 20px rgba(black, 0.3)
				z-index: 10
			img
				position: absolute
				top: 50%
				left: 0
				//transform: translateY(-50%)
				animation: wiggle 2s forwards infinite ease


		h2
			margin-bottom: 1rem
			font-size: 3.4rem
			color: $rot

		h4
			font-size: 2rem

			span
				font-size: inherit
		.formContent
			padding: 0
			margin-top: 2rem
			max-width: 100%!important
			text-align: left
			flex: 1 0 40%
			.question
				.next
					font-size: 1.6rem

				.inputSection
					display: block
					flex-wrap: wrap
					text-align: left
					margin: 2rem 0 4rem
					align-items: center
					strong
						font-size: 2rem
					.btn-outline
						border: none!important
						width: auto
						border-radius: 0
						padding: 0
						line-height: 1
						color: black
						&:hover
							background: transparent
							color: black
					.inputGroup
						flex: 1 0 40%
						min-width: 300px
						margin-top: 2rem
						// &~.inputGroup:not(.radioGroup)
						// 	@media (max-width: 960px)
						// 		margin-left: 2rem  
						text-align: left
						input[type="radio"], input[type="checkbox"] 
							display: none
							~ label
								padding-left: 2.4rem
								font-size: 1.6rem
								position: relative
								height: auto
								//margin-bottom: 1rem
								user-select: none
								&:before
									content: ""
									width: 15px
									height: 15px
									position: absolute
									left: 0
									top: -1px
									border: 1px solid darken($grau, 20%)
							&:checked
								~ label
									background: transparent
									&:before
										background: $rot
										border-color: $rot
									&:after
										content: ""
										width: 8px
										height: 3px
										border: 2px solid white
										border-top-color: transparent
										border-right-color: transparent
										position: absolute
										left: 3px
										top: 2px
										transform: rotate(-45deg)

							//display: inline-block
						input, label
							margin: 0
						&.success
							position: relative
							input
								border-color: #71ae73
								color: #71ae73
								display: block
							&:before
								content: ""
								position: absolute
								top: 0
								right: 0
								width: 4rem
								height: calc(100% - 4px)
								border-radius: 0 3rem 3rem 0
								background: url(../img/icn-checkmark-success.svg) center no-repeat
								background-size: 2rem
								background-color: #fff
								border: 2px solid #71ae73
								border-left: none

		

					strong, p   
						display: block
						width: 100%
					
		@media (max-width: 960px)
			display: block
			.form--header-content
				border-right: none
				margin-right: 0
		@media (max-width: 760px)
			display: block
			padding: 2rem
			.form--header-content
				border-right: none
				margin-right: 0
				padding-right: 2rem
				h2
					font-size: 1.6rem
			.formContent
				margin-top: 2rem
				.question
					strong
						font-size: 2rem!important
					p
						margin-bottom: 1rem
						margin-top: 0.5rem
					.inputSection
						.inputGroup
							min-width: 100%
							margin-top: 1rem
							label
								font-size: 1.6rem!important
								margin-bottom: 1rem!important
	
		&.testformular
			transition: all 300ms ease
			background: $grau
			margin: 0!important
			left: 0
			right: 0
			height: 100%

			&:before
				content: none

			#form
				width: 100%
				max-width: 1200px
				//background: $grau
		@media (max-width: 760px)
			padding: 2rem 1rem
			.form--header-content
				h2
					margin-bottom: 0
					font-size: 2rem!important
				.stoerer
					margin-top: 1rem
					h4
						font-size: 1.6rem
			.formContent
				margin-top: 0!important
				.question
					.inputSection
						strong
							font-size: 2rem!important
						*
							font-size: 1.6rem!important
						input[type="text"], input[type="number"], input[type="email"]
							font-size: 16px!important
						


		.btn-outline-input
			margin-bottom: 0
			outline: 0
			border: 2px solid #dedede
			padding: 1rem 2rem
			width: 100%
			border-radius: 3rem
			font-size: 1.8rem
			text-align: center
	.text--success, .alert
		font-size: 1.6rem
	.final--section
		display: block!important
	#timeout--notice
		font-size: 1.6rem
		margin-top: 1rem
	.toggle_check
		display: flex
		align-items: center
		padding-left: 0!important
		&:before
			content: none!important
		.toggle
			margin-right: 1rem
	input:checked
		&~.toggle_check
			.toggle
				&:after
					left: 2.2rem
					background: $rot
#mini
	#testplatz-sichern
		min-height: 80vh
		background: $hellgrau
		&:before
			content: none



#wirSuchenSie
	padding: 60px 20px
	background: $gradient3
	background: $hellgrau
	width: 100vw
	position: relative
	left: 50%
	right: 50%
	margin-left: -50vw
	margin-right: -50vw
	@media (max-width: 760px)
		padding: 1rem
	.akkordeonContent
		max-width: 1200px
		margin: 0 auto
		background: #ffffff
		border-radius: 8px
		box-shadow: $shadow
		.akkordeonContainer
			display: flex
			flex-wrap: wrap
			.akkordeonLeft
				padding: 40px 60px
				flex: 1 0 0
				position: relative
				&:after
					content: ""
					height: 80%
					width: 2px
					background: $hellgrau
					position: absolute
					right: 0
					top: 50%
					transform: translateY(-50%)
				.tablinks
					width: 100%
					font-weight: 600
					margin: 1rem 0
					cursor: pointer!important
					text-align: left
					border: none
					position: relative
					border: none
					background: none
					display: block
					user-select: none
					text-decoration: none
					font-size: 1.6rem
					&:after
						content: url('../img/angle_right_p.svg')
						position: absolute
						transform-origin: center
						transform: scale(0.9)
						right: 0
					&:before
						content: ""
						width: 0px
						height: 3px
						background: $rot
						position: absolute
						left: -60px
						top: 7px
						transition: .3s
					&:hover, &.active
						color: $rot
						&:before
							width: 30px
					&:focus
						color: $rot
						outline: none
						&:before
							width: 30px

			.akkordeonMid
				padding: 40px 30px
				position: relative
				flex: 2 0 0
				@media (max-width: 760px)
					padding: 2rem
				h3
					font-size: 3rem!important
					margin-bottom: 1rem
					font-family: univers
					font-weight: 700
					line-height: 1.2
				p
					//min-height: 150px
				.main__button
					margin-top: 2rem
				.tabcontent
					display: none
					animation: fadeEffect 1s
					@keyframes fadeEffect
						0%
							opacity: 0
						100%
							opacity: 1
			.akkordeonRight
				padding: 40px 60px
				flex: 1 0 0
				background: url('../img/akkordeonPicture1.jpg') no-repeat center
				background-size: cover
	@media (max-width: 960px)
		.akkordeonRight
			min-width: 100%
			min-height: 300px
	@media (max-width: 700px)
		.akkordeonLeft
			min-width: 100%
			&:after
				display: none
		.akkordeonMid
			&:after
				content: ""
				height: 2px
				width: 90%
				background: $hellgrau
				position: absolute
				top: 0
				left: 50%
				transform: translateX(-50%)


			


@keyframes wiggle
	0%
		transform: translate(0,-50%)
		top: 50%
	50%
		transform: translate(-10px,-50%)
		top: 50%
	100%
		transform: translate(0,-50%)
		top: 50%