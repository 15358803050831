#kundenumfragepage
	.container
		width: 100%
		max-width: 60rem
		margin: 0 auto
	h1
		text-align: center
		width: 100%
		max-width: 60rem
		margin: 0 auto
	.chart--container
		h2
			font-size: 3rem
			color: black
		text-align: center
		width: 100%
		max-width: 60rem
		margin: 3rem auto 
		.chart--row
			display: flex
			justify-content: center
			align-items: flex-end
			position: relative
			.chart--content
				display: flex
				flex-direction: column
				justify-content: flex-end
				margin: 0 4rem
				position: relative
				height: 30rem
				.chart--bar
					width: 8rem
					background: $rot
					height: 0
					transition: 500ms ease all
			.chart--line
				width: 100%
				height: 1px
				background: $hellgrau
				position: absolute
				left: 0
				@for $i from 0 through 10
					&:nth-child(#{$i})
						bottom: $i * 10%
				// &:nth-child(1)
				// 	bottom: 0
				// &:nth-child(2)
				// 	bottom: 10%
				// &:nth-child(3)
				// 	bottom: 20%
		.legende
			display: flex
			justify-content: center
			p
				margin: 2rem 4rem
				text-align: center
				width: 8rem
	.soft--row
		display: flex
		align-items: center
		margin-bottom: 2rem
	.ohr
		display: flex
		align-items: center
		justify-content: center
		background: $rot!important
		padding: 1rem
		height: 100px
		width: 100px
		min-height: 100px
		min-width: 100px
		border-radius: 50%
		margin-right: 20px
		img
			width: 100%!important
			max-width: 100%!important
	#warum
		margin-bottom: 4rem
		.content
			width: 100%
			max-width: 64rem
		h2
			margin-bottom: 0