.vorsorge--icon
    width: 15rem
    height: 15rem
    display: block
    margin: 2rem auto
    padding-top: 2rem
.benefits
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 2px solid $rot
    .benefit
        flex: 1 0 0
        border-right: 1px solid $hellgrau
        text-align: center
        padding: 0 1rem
        &:last-child
            border-right: none
        img
            display: block
            margin: 0 auto 1rem
        small
            font-size: 1rem
            line-height: 1.4
            color: $grau
    @media (min-width: $mobile)
        .benefit
            img
                width: auto
                height: 4rem
            small
                font-size: 1.4rem
    &.large
        flex-wrap: wrap
        .benefit
            flex: 1 0 40%
            margin-bottom: 2rem
        @media (min-width: $mobile)
            flex-wrap: nowrap
            .benefit
                margin-bottom: 0
                flex: 1 0 0
                

