.top-nav
    width: 100%
    .content
        padding: 0.5rem 2rem 0
        display: flex
        justify-content: space-between
        p
            font-size: 1.2rem
            color: $grau
    @media (min-width: $mobile)
        // display: none
        // border-bottom: 1px solid $hellgrau


    
nav
    display: flex
    align-items: center
    justify-content: space-between
    padding: 1rem 2rem
    background: #fff
    a
        display: block
        margin: 0
        padding: 0
    .logo
        width: 130px
        display: block
    .kundenzufriedenheit
        width: 80px
    @media (min-width: $mobile)
        .logo
            width: 200px
        .phone__number
            display: flex
            align-items: center
            text-decoration: none
            span
                font-family: $value
                font-weight: bold
                font-size: 3rem
                color: $rot
        .phone
            text-align: right
            display: flex
            align-items: center
            .kundenzufriedenheit
                margin-right: 3rem
            small
                min-width: 100%
                font-size: 1.2rem
                color: $grau
