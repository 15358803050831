*
  margin: 0
  padding: 0
  box-sizing: border-box
  // -webkit-font-smoothing: antialiased
  // moz-osx-font-smoothing: grayscale
  color: $black
  &:focus
    outline: none



html, body
  height: 100%
  font-size: 10px
  line-height: 1.4
  // transition: all 1s ease
  @media (min-width: $mobile)
    // font-size: 12px

body
  display: flex
  flex-direction: column
  justify-content: space-between
  background-color: $hellgrau
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
    display: block

img
  max-width: 100%

.main__content
  width: 100%
  max-width: 1200px
  margin: 0 auto
  background: #fff
  position: relative
  z-index: 0

#mboxImported-default-target-global-mbox-0
  display: none!important






