@font-face 
	font-family: 'value'
	src: url('../fonts/value/Value-Regular.woff2') format('woff2'), url('../fonts/value/Value-Regular.woff') format('woff')
	font-weight: normal
	font-style: normal


@font-face 
	font-family: 'value'
	src: url('../fonts/value/Value-Bold.woff2') format('woff2'), url('../fonts/value/Value-Bold.woff') format('woff')
	font-weight: bold
	font-style: normal


@font-face 
	font-family: 'univers'
	src: url('../fonts/univers/UniversLTStd.woff2') format('woff2'), url('../fonts/univers/UniversLTStd.woff') format('woff')
	font-weight: normal
	font-style: normal


@font-face 
	font-family: 'univers'
	src: url('../fonts/univers/UniversLTStd-Bold.woff2') format('woff2'), url('../fonts/univers/UniversLTStd-Bold.woff') format('woff')
	font-weight: bold
	font-style: normal

@font-face 
	font-family: 'Noto Sans'
	font-style: normal
	font-weight: 400
	src: url('../fonts/noto/noto-sans-v27-latin-regular.eot')
	src: local(''), url('../fonts/noto/noto-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/noto/noto-sans-v27-latin-regular.woff2') format('woff2'),  url('../fonts/noto/noto-sans-v27-latin-regular.woff') format('woff'),  url('../fonts/noto/noto-sans-v27-latin-regular.ttf') format('truetype'),  url('../fonts/noto/noto-sans-v27-latin-regular.svg#NotoSans') format('svg') 


@font-face 
	font-family: 'Noto Sans'
	font-style: normal
	font-weight: 500
	src: url('../fonts/noto/noto-sans-v27-latin-500.eot')
	src: local(''), url('../fonts/noto/noto-sans-v27-latin-500.eot?#iefix') format('embedded-opentype'),  url('../fonts/noto/noto-sans-v27-latin-500.woff2') format('woff2'),  url('../fonts/noto/noto-sans-v27-latin-500.woff') format('woff'),  url('../fonts/noto/noto-sans-v27-latin-500.ttf') format('truetype'),  url('../fonts/noto/noto-sans-v27-latin-500.svg#NotoSans') format('svg') 


@font-face 
	font-family: 'Noto Sans'
	font-style: italic
	font-weight: 400
	src: url('../fonts/noto/noto-sans-v27-latin-italic.eot')
	src: local(''), url('../fonts/noto/noto-sans-v27-latin-italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/noto/noto-sans-v27-latin-italic.woff2') format('woff2'),  url('../fonts/noto/noto-sans-v27-latin-italic.woff') format('woff'),  url('../fonts/noto/noto-sans-v27-latin-italic.ttf') format('truetype'),  url('../fonts/noto/noto-sans-v27-latin-italic.svg#NotoSans') format('svg') 


@font-face 
	font-family: 'Noto Sans'
	font-style: normal
	font-weight: 800
	src: url('../fonts/noto/noto-sans-v27-latin-800.eot')
	src: local(''), url('../fonts/noto/noto-sans-v27-latin-800.eot?#iefix') format('embedded-opentype'),  url('../fonts/noto/noto-sans-v27-latin-800.woff2') format('woff2'),  url('../fonts/noto/noto-sans-v27-latin-800.woff') format('woff'),  url('../fonts/noto/noto-sans-v27-latin-800.ttf') format('truetype'),  url('../fonts/noto/noto-sans-v27-latin-800.svg#NotoSans') format('svg') 



*
	font-family: $univers
	font-weight: 400

h1, h2
	font-size: 2.2rem	 
	font-weight: bold
	line-height: 1.3
	margin-bottom: 2rem
	font-family: $value
	color: $rot

h3
	font-size: 1.6rem
	font-weight: 600
	line-height: 1.6
	@media (min-width: $mobile)
		font-size: 1.8rem
p, a
	font-size: 1.6rem
	line-height: 1.4
	@media (min-width: $mobile)
		font-size: 1.8rem
@media (min-width: $mobile)
	ul
		li
			font-size: 1.8rem!important
	h1, h2
		font-size: 4rem
	h3
		font-size: 2rem!important

a
	color: black
	font-weight: 600

em
	font-family: $font

strong
	font-size: inherit
	font-weight: 600

.legal
	font-size: 1.3rem


